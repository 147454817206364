
const cftokenFns = {
    set: (cftoken) => {
        localStorage.setItem('cftoken', cftoken)
        return this
    },
    get: () => {
        return localStorage.getItem('cftoken')
    }
}

export default cftokenFns