import React, { useEffect, useState } from 'react'

const CarrierNavBar = ({ client }) => {
    const [clientName, setClientName] = useState('')
    const [clientLogo, setClientLogo] = useState('')

    useEffect(() => {
        if (client) {
            getClientNameAndLogo(client)
        }
    }, [client])

    const getClientNameAndLogo = async (client) => {
        let url = 'https://api.shippersedge.com/schedule/clientLogoAndFullName/' + client
        let myHeaders = new Headers();
        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        let request = new Request(url, requestOptions);
        let getClientNameAndLogoResponse = await fetch(request)
        let getClientNameAndLogoResponseJSON = await getClientNameAndLogoResponse.json()
        setClientName(getClientNameAndLogoResponseJSON.name)
        setClientLogo(getClientNameAndLogoResponseJSON.logo)
    }



    if (client) {
        return (
            <>
                <div className="row d-flex flex-row justify-content-center mt-1">
                    <div className="col-md-3 flex-fill"></div>
                    <div className="col-md-6 flex-fill">
                        <h1 style={{ marginTop: '1rem' }}>
                            {clientLogo ? (<img src={"https://www2.shippersedge.com//images/logos/" + clientLogo} alt={clientName + " logo"} height="70" title={clientName} />) : clientName}<br />
                            Dock Scheduler</h1>
                    </div>
                    <div className="col-md-3 flex-fill d-flex justify-content-center align-items-center">
                        {/* {clientLogo ? (<img src={"https://www2.shippersedge.com//images/logos/" + clientLogo} alt={clientName + " logo"} height="65" className="d-none d-md-block" />) : (<img src={"https://www2.shippersedge.com//images/logos/" + clientLogo} alt={clientName + " logo"} height="70" className="d-none d-md-block" />)} */}
                    </div>
                </div>
                {/* <hr className="border border-dark border-1 opacity-25"></hr> */}
            </>
        )
    } else {

        return null
    }
}

export default CarrierNavBar
