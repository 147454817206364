
import createChangeDeleteEventsAPI from './createChangeDeleteEventsAPI'
import transFormEvents from '../../utils/transformEvents'

const crudOrchestration = async function (event, role) {
    // console.log('crudOrchestration event:', event)

    let changedApptsEvent = event
    let events = []
    if (changedApptsEvent !== null) {
        if (Array.isArray(changedApptsEvent.data)) {
            for (let event of changedApptsEvent.data) {
                event.requestType = changedApptsEvent.requestType
                if (role) {
                    event.responsibleUser = role
                }
                events.push(event)
            }
        } else {
            changedApptsEvent.data.requestType = changedApptsEvent.requestType
            changedApptsEvent.data.responsibleUser = role
            events.push(changedApptsEvent.data)
        }


        let transformedAppointments = await transFormEvents('outgoing', events)

        // console.log('transformedAppointments going out', transformedAppointments)

        // Send updates to back end
        let promises = []
        transformedAppointments.forEach((change) => {
            promises.push(createChangeDeleteEventsAPI(change))
        })

        let crudEventsResults = 'saved'

        await Promise.allSettled(promises).then((results) => {
            results.forEach((result) => {
                // console.log('crudOrchestration promise all result: ', result.status)
                if (result.status === 'rejected') { crudEventsResults = 'error ' }
            })
        })
        return crudEventsResults
    } else {
        console.error('crudOrchestration saw null')
        return ('crudOrchestration saw null')
    }

};

export default crudOrchestration