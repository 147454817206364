import React from 'react'

const USSCardDates = ({ shipment, mode }) => {

    if (mode === "pickup") {
        if (shipment.shipper.dates.available1.toLocaleDateString() === shipment.shipper.dates.available2.toLocaleDateString()) {
            return (
                <>
                    PU Date: {shipment.shipper.dates.available1.toLocaleDateString()}
                </>
            )
        } else {
            return (
                <>
                    PU Date1: {shipment.shipper.dates.available1.toLocaleDateString()}<br />PU Date2: {shipment.shipper.dates.available2.toLocaleDateString()}
                </>
            )
        }
    }
    if (mode === "delivery") {
        return (
            <>
                Del Req: {shipment.consignee.dates.requestedDeliveryDate.toLocaleDateString()}
            </>
        )
    } else {
        return (
            <>
                UnScheduledShipmentCardDates: No Mode
            </>
        )
    }
}

export default USSCardDates
