import React, { useState, useEffect } from 'react'
import getRruleCustomFields from '../../../../utils/API/getRruleCustomFields'
import saveRruleCustomFields from '../../../../utils/API/saveRruleCustomFields'

const ApptEditorTemplateRruleCustomFields = ({ props, localusercode, currentlySelectedDate, supplierWindowsMode }) => {
    // console.log('ApptEditorTemplateRruleCustomFields', props, localusercode)
    let [rruleCustomFieldData, setRruleCustomFieldData] = useState(null)
    let [origionaRrulelCustomFieldData, setOrigionalRruleCustomFieldData] = useState(null)
    let [mode, setMode] = useState('nodata')




    useEffect(() => {

        // console.log('ApptEditorTemplateRruleCustomFields', props.timeIn.slice(0, 10))
        // let date = props.timeIn.slice(0, 10)
        if (localusercode !== 'kwiktrip') {
            fetchData(props.id, currentlySelectedDate)
        }
    }, [])

    if (supplierWindowsMode) {
        return (null)
    }

    async function fetchData(dockEventId, date) {
        // console.log('ApptEditorTemplateRruleCustomFields fetchData request', dockEventId, date)
        let result = await getRruleCustomFields(dockEventId, date)
        // console.log('ApptEditorTemplateRruleCustomFields fetchData result', result)
        if (result && result?.data.length) {
            setRruleCustomFieldData(result.data[0].data)
            setOrigionalRruleCustomFieldData(result.data[0].data)
            setMode('display')
        }

    }

    function editToggle() {
        if (mode === "display") {
            setMode('edit')
        } else {
            setMode('display')
        }
    }

    function changeHandler(event) {
        // console.log(event.target.value)
        setRruleCustomFieldData(event.target.value)
    }

    async function save() {
        let saveResult = await saveRruleCustomFields(props.id, currentlySelectedDate, rruleCustomFieldData)
        // console.log('ApptEditorTemplateRruleCustomFields saveResult', saveResult)
        if (saveResult) {
            editToggle()
            fetchData(props.id, currentlySelectedDate)
        }
    }

    function cancel() {
        editToggle()
        setRruleCustomFieldData(origionaRrulelCustomFieldData)
    }

    if (localusercode === 'kwiktrip') {
        return (null)
    }

    if (mode === 'display') {
        return (
            <div> Order:<br />
                {rruleCustomFieldData}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16" onClick={() => editToggle()}>
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
            </div>
        )
    }

    if (props.recurrenceRule && mode === 'edit') {

        return (
            <div className="d-flex flex-column">
                <div>
                    <input style={{ width: '100px' }} value={rruleCustomFieldData} onChange={(event) => changeHandler(event)} />
                </div>
                <div className="d-flex flex-row mt-3">
                    <div><button type="button" className="btn btn-primary btn-sm" onClick={() => save()}>Save</button> </div> &nbsp;
                    <div><button type="button" className="btn btn-secondary btn-sm" onClick={() => cancel()}>Cancel</button></div>
                </div>

            </div>)
    }

    if (props.recurrenceRule && mode === 'nodata') {
        return (
            <div className="d-flex flex-column">

                <div>
                    <label>Order:</label>
                    <input style={{ width: '100px' }} value={rruleCustomFieldData} onChange={(event) => changeHandler(event)} />
                </div>
                <div className="d-flex flex-row mt-3">
                    <div><button type="button" className="btn btn-primary btn-sm" onClick={() => save()}>Save</button> </div> &nbsp;
                    <div><button type="button" className="btn btn-secondary btn-sm" onClick={() => cancel()}>Cancel</button></div>
                </div>

            </div>)
    } else {
        return (null)
    }
}

export default ApptEditorTemplateRruleCustomFields