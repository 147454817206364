import React from 'react'

const Errors = ({ pageMode, driverCode, errorText }) => {
    if (pageMode === 'no appointment accessorials') {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-3" />
                    <div className="col-6">
                        <div className="mt-4 d-flex flex-column justify-content-center">
                            <a href="https://www.shippersedge.com/"><img src="/shippersedge-logo.png" alt="logo" height="100px" /></a>
                            <span className="TitleText mb-3 mt-3">Dock Scheduler</span>
                            <br />
                            <div className="alert alert-warning" role="alert">
                                A shipment with code {driverCode} was found but it has no pickup or delivery appointment accessorials.
                            </div>
                        </div>
                    </div>
                    <div className="col-3" />
                </div>
            </div>
        )
    } else if (pageMode === 'error no shipment found') {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-3" />
                    <div className="col-6">
                        <div className="mt-4 d-flex flex-column justify-content-center">
                            <a href="https://www.shippersedge.com/"><img src="/shippersedge-logo.png" alt="logo" height="100px" /></a>
                            <span className="TitleText mb-3 mt-3">Dock Scheduler</span>
                            <br />
                            <div className="alert alert-warning" role="alert">
                                No shipment with driverCode {driverCode} was found.
                            </div>
                        </div>
                    </div>
                    <div className="col-3" />
                </div>
            </div>
        )
    } else if (pageMode === 'error') {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-3" />
                    <div className="col-6">
                        <div className="mt-4 d-flex flex-column justify-content-center">
                            <a href="https://www.shippersedge.com/"><img src="/shippersedge-logo.png" alt="logo" height="100px" /></a>
                            <span className="TitleText mb-3 mt-3">Dock Scheduler</span>
                            <br />
                            <div className="alert alert-warning" role="alert">
                                An Unexpected Error Has Occured.
                                {errorText ? <><br /><br /> {errorText}</> : null}<br />
                            </div>
                        </div>
                    </div>
                    <div className="col-3" />
                </div>
            </div>
        )
    } else {
        console.warn("Errors.js was sent an unknown error.")
        return (
            <div className="container">
                <div className="row">
                    <div className="col-3" />
                    <div className="col-6">
                        <div className="mt-4 d-flex flex-column justify-content-center">
                            <a href="https://www.shippersedge.com/"><img src="/shippersedge-logo.png" alt="logo" height="100px" /></a>
                            <span className="TitleText mb-3 mt-3">Dock Scheduler</span>
                            <br />
                            <div className="alert alert-warning" role="alert">
                                An unknown error has occured.
                            </div>
                        </div>
                    </div>
                    <div className="col-3" />
                </div>
            </div>
        )
    }
}
export default Errors
