import React from 'react'
import Products from './Products/Products';

const ShipmentDetails = ({ shipment, show }) => {

    if (shipment && show) {

        const capitalizeFirstLowercaseRest = str => {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        };

        return (
            <div>
                <h4>Shipment Details</h4>
                <div>Total Weight: {shipment.details?.totalWeight} lbs</div>
                <div>Total Handling Units: {shipment.details?.totalHandlingUnits}</div>
                {shipment.details?.equipment && shipment.details.equipment.length && shipment.details.equipment !== "" ?
                    <div>
                        Equipment: {shipment.details.equipment.map((equip, index) => <span key={index}>{capitalizeFirstLowercaseRest(equip)} &nbsp;</span>)}
                    </div>
                    : null}
                <Products shipment={shipment} />
            </div>
        )


    } else {
        return null
    }

}

export default ShipmentDetails