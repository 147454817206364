import React from 'react'

const FourOhFour = () => {
    return (
        <div className="container">
            <header className="">

                <a href="https://www.shippersedge.com/"><img src="/shippersedge-logo.png" alt="logo" height="100px" className="mt-5" /></a><br />
                <span className="TitleText mb-3 mt-3">Dock Scheduler</span>
                <br /><br />

            </header>
            <h3>Expired Dock Manager Link</h3>
            <div className="row mt-3">
                <div className="col-3">

                </div>
                <div className="col-6">
                    <div className="alert alert-warning" role="alert">
                        <p>Please check the link that brought you here.</p>
                        <p>The link you have to the Dock Scheduler Manager may have changed or expired.</p>
                    </div>
                </div>
                <div className="col-3">

                </div>
            </div>


        </div >
    )
}

export default FourOhFour
