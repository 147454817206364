import { useState, useEffect } from 'react';
import useManagerView from "./hooks/useManagerView"



const useManager = (props) => {

    const [urlParams, setUrlParams] = useState(props)
    const [location, setLocation] = useState(null)
    const [UUID, setUUID] = useState(null)
    const [pageMode, setPageMode] = useState('loading')

    const [userRole, setUserRole] = useState(null)

    // Status pannel
    const [status, setStatus] = useState('start')

    // Updating overlay
    const [updatingOverlayStatus, setUpdatingOverlayStatus] = useState(false)

    // Debug setting for additional console outputs
    const [debug, setDebug] = useState(false)

    // useDockEventsAndSchedules manages api calls to get dock events, get schedules, filter dock events depending on the selected dockand set the selected dock

    const { getManagerView, docks, setDocks, refreshData, usercode, unScheduledShipments, locationSettings, lastUpdateDate } = useManagerView(location, UUID, debug, setPageMode, setUserRole)

    useEffect(() => {
        if (urlParams) {
            setLocation(urlParams.match.params.locationId)
            setUUID(urlParams.match.params.UUID)
            // console.log('setting location')
        }

    }, [urlParams]) // eslint-disable-line

    useEffect(() => {
        if (debug) {
            console.log('useManager saw docks', docks)
            // if (docks) {
            //     docks.forEach(dock => {
            //         if (dock?.events && dock.events.length) {
            //             dock.events.forEach(event => {
            //                 if (event.id === 2536) {
            //                     console.log('saw event id 2536', event)
            //                 }
            //             });
            //         }
            //     });
            // }
        }

        if (pageMode === "updating") {
            setPageMode("running")
        } else {
            if (docks !== null && pageMode !== 'running') {
                if (debug) { console.log('useManager set RUNNING') }
                setPageMode('running')
            }
        }
    }, [docks]) // eslint-disable-line


    // useEffect(() => {
    //     if (pageMode === "updating") {
    //         window.location.reload()
    //     }
    // }, [pageMode])

    // async function startUp() {

    return { pageMode, docks, setDocks, usercode, status, setStatus, getManagerView, updatingOverlayStatus, setUpdatingOverlayStatus, debug, refreshData, unScheduledShipments, userRole, locationSettings, location, lastUpdateDate }
}

export default useManager