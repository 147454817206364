import React from 'react'
import LoadingIcon from '../../LoadingIcon/LoadingIcon'

const Updating = () => {
    return (
        <div className="container">
            <div className="row mt-4">
                <div className="col-3"></div>
                <div className="col-6">
                    <div className="d-flex flex-column justify-content-center">
                        {/* <a href="https://www.shippersedge.com/"><img src="../../../shippersedge-logo.png" alt="logo" height="100px" /></a> */}
                        <span className="TitleText mb-5 mt-3">Dock Scheduler</span>
                        {/* <div className="alert alert-secondary" role="alert"> */}
                        <div className="mb-3">Updating.... </div>

                        {/* </div> */}
                        <div className="d-flex flex-row justify-content-center">
                            <LoadingIcon />
                        </div>
                        {/* <div className="spinner">
                            <div className="rect1"></div>
                            <div className="rect2"></div>
                            <div className="rect3"></div>
                            <div className="rect4"></div>
                            <div className="rect5"></div>
                        </div> */}
                    </div>
                    <div className="col-3"></div>
                </div>
            </div>
        </div>
    )
}

export default Updating
