import moment from 'moment'

const checkForBadEvent = async function (event, role) {
    // console.log('checkForBadEvent', event)
    let changedApptsEvent = event

    if (changedApptsEvent !== null) {
        if (Array.isArray(changedApptsEvent.data)) {
            for (let event of changedApptsEvent.data) {
                let start = moment(event.StartTime).utc(true)
                let end = moment(event.EndTime).utc(true)
                let momentResult = start.isSame(end)
                // console.log('checkForBadEvent', start, end, momentResult)
                if (momentResult) {

                    return true
                } else {
                    return false
                }
            }
        } else {

            let start = moment(changedApptsEvent.data.StartTime).utc(true)
            let end = moment(changedApptsEvent.data.EndTime).utc(true)
            let momentResult = start.isSame(end)
            // console.log('checkForBadEvent', start, end, momentResult)
            if (momentResult) {
                return true
            } else {
                return false
            }
        }
    } else {
        console.error('checkForBadEvent saw null')
        return ('checkForBadEvent saw null')
    }

};

export default checkForBadEvent