
export const getManagerView = function (
    location,
    timeOutValue
) {
    if (location === undefined) {
        console.error('Oh noes getManagerView location is undefined!')
    }
    return new Promise(function (resolve, reject) {

        let controller = new AbortController();

        let url =
            "https://api.shippersedge.com/schedule/managerView/"

        let myHeaders = new Headers();
        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
        myHeaders.append("content-type", "application/json;charset=UTF-8");

        var raw = JSON.stringify({ "location": location });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        let request = new Request(url, requestOptions);

        if (timeOutValue) {
            setTimeout(() => controller.abort(), timeOutValue);
        }

        fetch(request)
            .then((response) => {
                // console.log(
                //   "debug getManagerView.js response",
                //   response
                // );
                if (!response.ok) {
                    console.error(
                        "getManagerView.js error",
                        response.statusText,
                        response
                    );
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                console.error("getManagerView API Request failed", err);
                resolve(err);
            });
    });




    //     var myHeaders = new Headers();
    // myHeaders.append("Connection", "keep-alive");
    // myHeaders.append("Pragma", "no-cache");
    // myHeaders.append("Cache-Control", "no-cache");
    // myHeaders.append("sec-ch-ua", "\"Chromium\";v=\"94\", \"Microsoft Edge\";v=\"94\", \";Not A Brand\";v=\"99\"");
    // myHeaders.append("DNT", "1");
    // myHeaders.append("content-type", "application/json");
    // myHeaders.append("sec-ch-ua-mobile", "?0");
    // myHeaders.append("User-Agent", "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.81 Safari/537.36 Edg/94.0.992.50");
    // myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
    // myHeaders.append("sec-ch-ua-platform", "\"macOS\"");
    // myHeaders.append("Accept", "*/*");
    // myHeaders.append("Origin", "https://scheduler.shippersedge.com");
    // myHeaders.append("Sec-Fetch-Site", "same-site");
    // myHeaders.append("Sec-Fetch-Mode", "cors");
    // myHeaders.append("Sec-Fetch-Dest", "empty");
    // myHeaders.append("Referer", "https://scheduler.shippersedge.com/");
    // myHeaders.append("Accept-Language", "en-US,en;q=0.9");

    // var raw = JSON.stringify({"location":6,"debug":"true"});

    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow'
    // };

    // fetch("https://api.shippersedge.com/schedule/managerView/", requestOptions)
    //   .then(response => response.text())
    //   .then(result => console.log(result))
    //   .catch(error => console.log('error', error));

};

export default getManagerView