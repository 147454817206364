import React from 'react'

const ConsigneeDocs = ({ shipment }) => {
    if (shipment?.consignee?.documents && shipment.consignee.documents.length) {
        return (
            <>
                <span className="bold">Delivery Documents:</span><br />
                {shipment.consignee.documents.map((document, index) => <a href={document.url} target="_blank" rel="noopener noreferrer " key={index}><span className="text-capitalize">{document.name}</span></a>)}
            </>)
    } else { return null }
}

export default ConsigneeDocs