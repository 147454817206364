import React from 'react'

const Products = ({ shipment }) => {
    if (shipment) {
        let products = []


        shipment.handlingUnits.forEach(hu => {
            hu.items.forEach(item => {
                products.push(item.description)
            })
        })

        let uniqueProducts = [...new Set(products)]
        console.log('uniqueProducts: ', uniqueProducts)
        return (
            <div>
                Products:
                <ul >{uniqueProducts.map((product, index) => <li key={index}>{product}</li>)}</ul>
            </div >
        )
    } else {
        return null
    }

}

export default Products