import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const KwikTripConsigneeLocationInfo = (consigneeLocationInfo) => {
    if (!consigneeLocationInfo) return null
    if (!consigneeLocationInfo.managerName) return null
    if (!consigneeLocationInfo.managerPhone) return null
    return (
        <Row>
            <Col>
                <div className="card mt-3">
                    <div className="card-body">
                        <p>If you can not meet the available appointment times, please contact the location contact.</p>
                        <p>Location Contact: {consigneeLocationInfo.managerName}</p>
                        <p>Phone: {consigneeLocationInfo.managerPhone}</p>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default KwikTripConsigneeLocationInfo