import React from 'react'

const Unauthorized = () => {
    return (
        <div className="container">
            <div className="row mt-4">
                <div className="col-3"></div>
                <div className="col-6">
                    <div className="d-flex flex-column justify-content-center">
                        {/* <a href="https://www.shippersedge.com/"><img src="../../../shippersedge-logo.png" alt="logo" height="100px" /></a> */}
                        <div>
                            <img src="https://scheduler.shippersedge.com/shippersedge-logo.png" alt="logo" height="10px" className="img-fluid" />
                        </div>
                        <span className="TitleText mt-3">Dock Scheduler</span>
                        {/* <div className="alert alert-secondary" role="alert"> */}
                        <div className="alert alert-warning mt-3" role="alert">
                            <span className="TitleText ">Unauthorized</span>
                        </div>
                        <div className="alert alert-dark" role="alert">
                            Your link to the ShippersEdge Dock Scheduler may have expired.  Please visit the ShippersEdge TMS to obtain a new one or contact the person who sent you the link.
                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
            </div>
        </div >
    )
}

export default Unauthorized