import React, { useState } from 'react'
import { useSelectedDate } from '../../../utils/Contexts/SelectedDateContext'
import moment from 'moment'

const PrintScheduleBtn = ({ usercode, location, selectedDock, humanReadableToday }) => {

    const { selectedDate } = useSelectedDate()

    const [dateToUse, setDateToUse] = useState(!selectedDate ? !JSON.parse(localStorage.getItem('schedule')) ? moment(new Date()).utc(true).format('YYYY-MM-DD') : moment(JSON.parse(localStorage.getItem('schedule')).selectedDate).utc(true).format('YYYY-MM-DD') : moment(selectedDate).utc(true).format('YYYY-MM-DD'))

    function changeDate(date) {
        setDateToUse(moment(date).utc(true).format('YYYY-MM-DD'))
    }

    if (!usercode || !location || !selectedDock || !humanReadableToday) return null

    let url = `https://api.shippersedge.com/reports/global/shiftsDockSchedulerGlobal?locationId=${location}&date=${dateToUse}`

    return (

        <div className="input-group input-group-sm ">
            <input className='form-control' type="date" value={dateToUse} onChange={(event) => { changeDate(event.target.value) }} style={{ height: '32px' }} />
            <div className="input-group-append">
                <span className='input-group-text' style={{ cursor: 'pointer', height: '32px' }}>
                    <a href={url} target="_blank" rel="noreferrer" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-printer" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                        </svg>
                    </a>
                </span>
            </div>
        </div>

    )
}

export default PrintScheduleBtn