export const getConsolidatedShipment = async (consolidationWebquotecontrol, orderWebquotecontrol, setConsolidationShipment) => {
    if (consolidationWebquotecontrol === orderWebquotecontrol) {
        return false
    }
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    let webquotecontrol = consolidationWebquotecontrol
    let url = "https://api.shippersedge.com/v4/shipment/" + webquotecontrol + '/?include=all'
    let ship = await fetch(url, requestOptions)
        .then(response => response.text())
        .then(result => {
            if (setConsolidationShipment === null) {
                return JSON.parse(result)
            } else {
                setConsolidationShipment(JSON.parse(result))
                return true
            }
        })
        .catch(error => {
            console.error('ApptEditorTemplate getAllStopInfoFromConsolidatedShipment error', error)
            return false
        });
    if (setConsolidationShipment === null) {
        return ship
    }

}

export default getConsolidatedShipment