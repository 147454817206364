import React, { useState, useEffect } from 'react'
import ApptEditorTemplateShipmentDetails from '../ApptEditorTemplateComponents/ApptEditorTemplateShipmentDetails'
import getAndSetMultipleShipments from '../../../../utils/helpers/getAndSetMultipleShipments'
import getConsolidatedShipment from '../../../../utils/helpers/getConsolidatedShipment'


// Displaying shipment details for a single shipment is easy we display all items.  However, if it is a consolidation, we need to display the details of all the shipments in the consolidation for that given stop ONLY.  If this is an appointment for multiple shipments, we need to display the details of all the shipments in the appointment for the gven stop that is scheduled.  In the more complex cases that's not simply everything in the shipment.  This component will handle all of that logic.
const DetailsWrapper = ({ appointmentProps, shipment }) => {

    const [isConsolidation, setIsConsolidation] = useState(false)
    const [consolidationShipment, setConsolidationShipment] = useState(null)
    const [multiShipmentApptMode, setMultiShipmentApptMode] = useState(false)
    // These are simply all the shipments in the appointment if there are multiple shipments in the appointment.
    const [multiShipmentApptModeOrdersNoValidation, setMultiShipmentApptModeOrdersNoValidation] = useState(null)

    let multiShipmentApptModeProps = false
    if (appointmentProps?.webquotecontrol && typeof appointmentProps.webquotecontrol === 'string' && appointmentProps.webquotecontrol.includes(',')) {
        multiShipmentApptModeProps = true
    }

    useEffect(() => {
        // Existing appointment for a multi order (multi shipment) appointment
        if (multiShipmentApptModeProps) {
            // console.log('ApptEditorTemplate multiOrderSingleAppt props.webquotecontrol', props.webquotecontrol)
            let orderList = appointmentProps.webquotecontrol.replace(/\s/g, '').split(',')
            // console.log('ApptEditorTemplate multiOrderSingleAppt orderList', orderList)
            getAndSetMultipleShipments(orderList, setMultiShipmentApptModeOrdersNoValidation)
        }
    }, [])

    useEffect(() => {
        // After ShipmentDetails is populated need to check if this shipment is a consolidation shipment 

        if (shipment && shipment?.details?.trip) {

            setIsConsolidation(true)

            getConsolidatedShipment(shipment.details.trip, shipment?.id, setConsolidationShipment)

        }
    }, [shipment, appointmentProps.webquotecontrol, appointmentProps.id])


    if (!appointmentProps) return null
    if (!appointmentProps.webquotecontrol) return null
    if (!shipment) return null


    return (
        <>
            {/* <div>DetailsWrapper</div>
            <ul>
                <li>appointment: {appointmentProps.webquotecontrol}</li>
                <li>shipment: {shipment.id}</li>
                <li>trip: {shipment?.details?.trip}</li>
                <li>isConsolidation: {isConsolidation ? 'true' : 'false'}</li>
                <li>consolidationShipment: {consolidationShipment?.id}</li>
            </ul> */}

            <ApptEditorTemplateShipmentDetails shipment={shipment} isConsolidation={isConsolidation} consolidationShipment={consolidationShipment} appointmentInfo={appointmentProps} multiShipmentApptMode={multiShipmentApptMode} />
        </>
    )
}

export default DetailsWrapper