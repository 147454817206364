import React from 'react'

const DockSelector = ({ docks, selectedDock, setSelectedDock }) => {

    const setTheClickedDock = (dock) => {
        setSelectedDock(dock)
    }

    const greyOutSmallTextStyle = {
        fontSize: '0.8rem',
        color: 'grey'
    }

    if (docks.length > 1) {
        return (
            <div className="mb-3">

                <div className="d-flex">

                    {docks.map((dock) => {
                        let inboundOrOutbound = ''
                        let differeingInboundOutboundSettings = false

                        if (dock.inbound !== dock.outbound) {
                            differeingInboundOutboundSettings = true
                        }
                        if (differeingInboundOutboundSettings) {
                            if (dock.inbound === 1) {
                                inboundOrOutbound = 'Inbound Only'
                            } else if (dock.outbound === 1) {
                                inboundOrOutbound = 'Outbound Only'
                            }
                        }

                        // Adjust the span rendering for selected and non-selected docks
                        return (
                            <span
                                key={dock.id}
                                className={`flex-fill text-center pointerElement ${dock.name === selectedDock.name ? 'fs-5 border-bottom border-primary' : ''}`}
                                onClick={() => setTheClickedDock(dock)} // Pass dock object directly
                            >
                                {dock.name}
                                {inboundOrOutbound ? (
                                    <>
                                        <br />
                                        <span style={greyOutSmallTextStyle}>{inboundOrOutbound}</span>
                                    </>
                                ) : null}
                            </span>
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default DockSelector