import React from 'react'

const USSCardAddress = ({ shipment, mode }) => {


    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    if (mode === "pickup") {
        return (
            <>
                {shipment.shipper.address.line1}<br />
                {shipment.shipper.address.line2 && shipment.shipper.address.line2 !== "" ? <>{shipment.shipper.address.line2}<br /></> : null}
                {shipment.shipper.address.city}, {shipment.shipper.address.state} {shipment.shipper.address.zip}<br /><br />

                {shipment.shipper.contact.name && shipment.shipper.contact.name !== "" ? <>{shipment.shipper.contact.name}<br /></> : null}

                {shipment.shipper.contact.phone && shipment.shipper.contact.phone !== "" ? <span className='text-break'>
                    <a href={`tel:${shipment.shipper.contact.phone}`} >{formatPhoneNumber(shipment.shipper.contact.phone)}</a><br /></span> : null}

                {shipment.shipper.contact.email && shipment.shipper.contact.email !== "" ? <span className='text-break'>
                    <a href={`mailto:${shipment.shipper.contact.email}?subject=${shipment.usercontrol}`} >{shipment.shipper.contact.email}</a><br /></span> : null}
            </>
        )
    }
    if (mode === "delivery") {
        return (
            <>
                {shipment.consignee.address.line1}<br />
                {shipment.consignee.address.line2 && shipment.consignee.address.line2 !== "" ? <>{shipment.consignee.address.line2}<br /></> : null}
                {shipment.consignee.address.city}, {shipment.consignee.address.state} {shipment.consignee.address.zip}<br /><br />


                {shipment.consignee.contact.phone && shipment.consignee.contact.phone !== "" ? <span className='text-break'>
                    <a href={`tel:${shipment.consignee.contact.phone}`} >{formatPhoneNumber(shipment.consignee.contact.phone)}</a><br /></span> : null}

                {shipment.consignee.contact.email && shipment.consignee.contact.email !== "" ? <span className='text-break'>
                    <a href={`mailto:${shipment.consignee.contact.email}?subject=${shipment.usercontrol}`} >{shipment.consignee.contact.email}</a><br /></span> : null}
            </>
        )
    } else {
        return (
            <>
                USSCardAddress saw no mode.
            </>
        )
    }

}

export default USSCardAddress
