export const getRruleCustomFields = async (dockEventId, date) => {
    // console.log('getRruleCustomFields', dockEventId, date)
    var myHeaders = new Headers();
    let urlSearchParams = new URLSearchParams({
        dockEventId: dockEventId,
        date: date,
    })
    myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    let result = await fetch("https://api.shippersedge.com/schedule/recurringEventsFields/?" + urlSearchParams, requestOptions)
        .then(response => response.text())
        .then(result => {
            let returnFromAPI = JSON.parse(result)
            // console.log('getRruleCustomFields', returnFromAPI)
            return returnFromAPI
        })
        .catch(error => {
            // console.log('getRruleCustomFields error', error)
            return false
        });

    return result

};

export default getRruleCustomFields