import React from 'react'

const ShipperDocs = ({ shipment }) => {
    if (shipment?.shipper?.documents && shipment.shipper.documents.length) {
        return (
            <>
                <span className="bold">Shipping Documents:</span><br />
                {shipment.shipper.documents.map((document, index) => <a href={document.url} target="_blank" rel="noopener noreferrer " key={index}><span className="text-capitalize">{document.name}</span></a>)}
            </>)
    } else { return null }
}

export default ShipperDocs