import React from 'react'
import USSCardDates from './Components/USSCardDates'
import USSCarrier from './Components/USSCarrier'
import USSCardAddress from './Components/USSCardAddress'

export const UnScheduledShipmentCard = ({ shipment, mode }) => {
    // console.log('UnScheduledShipmentCard', shipment)
    return (
        <div className='card m-3' style={{ width: '60%' }}>
            <div className="card-header">
                <div className='row'>
                    {/* Ref Numbers */}
                    <div className='col text-start'>
                        <strong>BOL:</strong> {shipment.id}<br />
                        <span className="text-break"> <strong>UC:</strong> {shipment.usercontrol}</span><br />
                        {shipment.status.code}
                    </div>
                    {/* Dates */}
                    <div className='col text-start'>
                        <USSCardDates shipment={shipment} mode={mode} />
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col text-start'>
                        <USSCarrier shipment={shipment} />
                    </div>
                    <div className='col text-start'>
                        <USSCardAddress shipment={shipment} mode={mode} />
                    </div>
                </div>
            </div>
        </div>
    )

}
