// \r\n\r\n
export const validateMultiOrderOneApptOrders = async (allOrdersShipments, multipleOrders, existingApptId, selectedDock) => {

    let debug = false
    if (debug) { console.log('validateMultiOrderOneApptOrders') }
    // Kwik trip LTL order example 4500281147
    // Kwik Trip order in a consolidation TestTT23071004
    // messed up order test list 4500281147,TestTT23071004

    let allOrdersValidated = true
    let allOrdersValidatedMessage = []

    // Are any of the orders not found (empty object from shipmentv4)?
    function isOrderNotFound(arrayOfObjects, multipleOrders) {
        if (debug) { (console.log('isOrderNotFound')) }
        let anyNotFound = false
        for (const obj of arrayOfObjects) {
            if (Object.keys(obj).length === 0) {
                anyNotFound = true
            }
        }
        if (anyNotFound) {
            for (const order of multipleOrders) {
                if (debug) { console.log('order', order) }
                let checkingOrderResult = allOrdersShipments.find(o => o.usercontrol === order)
                if (debug) { console.log('checkingOrderResult', checkingOrderResult) }
                if (checkingOrderResult === undefined) {
                    allOrdersValidated = false
                    let reason = { message: 'Order not found.', offender: order }
                    allOrdersValidatedMessage.push(reason)
                }
            }
        }
    }
    isOrderNotFound(allOrdersShipments, multipleOrders)

    if (!allOrdersValidated) {
        // If any of the orders are not found, return at this point as furth validation is not possible on the empty object (not found) orders.
        return { allOrdersValidated, allOrdersValidatedMessage }
    }

    // Are there any dupicate orders in allOrdersShipments and return duplicate?
    function hasDuplicateIdsAndReturnDups(arrayOfObjects) {
        if (debug) { console.log('hasDuplicateIdsAndReturnDups') }
        let ids = []
        let duplicates = []
        for (const obj of arrayOfObjects) {
            if (ids.includes(obj.id)) {
                duplicates.push(obj.usercontrol)
            } else {
                ids.push(obj.id)
            }
        }
        return duplicates
    }
    const hasDuplicateIdsCheck = hasDuplicateIdsAndReturnDups(allOrdersShipments)
    if (hasDuplicateIdsCheck.length > 0) {
        allOrdersValidated = false
        let reason = { message: 'Duplicate orders found in list.', offender: hasDuplicateIdsCheck.join(', ') }
        allOrdersValidatedMessage.push(reason)
    }

    // Consolidations allowed to be added as of April 4 2024
    // // Are there any orders that are a part of a consolidation (not allowed)?
    // function isConsolidationAndReturnConsolidation(arrayOfObjects) {
    //     if (debug) { console.log('isConsolidationAndReturnConsolidation') }
    //     let consolidationUCs = []
    //     for (const obj of arrayOfObjects) {
    //         if (obj.details.trip !== null) {
    //             if (debug) { console.log('trip? ', obj.details.trip) }
    //             consolidationUCs.push(obj.usercontrol)
    //         }
    //     }
    //     return consolidationUCs
    // }
    // let consolidationCheck = isConsolidationAndReturnConsolidation(allOrdersShipments)
    // if (consolidationCheck.length > 0) {
    //     allOrdersValidated = false
    //     let reason = { message: 'One or more orders are a part of a consolidation.', offender: consolidationCheck.join(', ') }
    //     allOrdersValidatedMessage.push(reason)
    // }

    // Are any of the orders already scheduled for an appointment?
    let isAlreadyScheduledTime = ""
    async function isAlreadyScheduled(arrayOfObjects) {
        let isAlreadyScheduledUCs = []
        for (const obj of arrayOfObjects) {
            let webquotecontrol = obj.id
            let url = `https://api.shippersedge.com/schedule/events/?webquotecontrol=${webquotecontrol}`

            var myHeaders = new Headers()
            myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW")

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            let response = await fetch(url, requestOptions)
            let data = await response.json()
            // console.log('isAlreadyScheduled data existing', data[0]?.id, existingApptId)
            // It's ok if you validate and find the same appointment id as the one you are editing.
            if (data && data.length > 0 && data[0]?.id !== existingApptId) {
                let rawTime = data[0]?.timeIn
                // raw date time ("2023-09-20T13:45:00.000Z") human readable date and time
                let dateTime = new Date(rawTime)
                let date = dateTime.toLocaleDateString()
                let time = dateTime.toLocaleTimeString()
                isAlreadyScheduledTime = `${date} ${time}`
                isAlreadyScheduledUCs.push(obj.usercontrol)
            }
        }
        return isAlreadyScheduledUCs
    }
    let alreadyScheduledCheck = await isAlreadyScheduled(allOrdersShipments)

    if (alreadyScheduledCheck.length > 0) {
        allOrdersValidated = false
        let reason = { message: 'This order is already scheduled for an appointment. ' + isAlreadyScheduledTime, offender: alreadyScheduledCheck.join(', ') }
        allOrdersValidatedMessage.push(reason)
        isAlreadyScheduledTime = ""
    }

    // Is the order supposed to be scheduled at this dock
    function isWrongDock(allOrdersShipments, multipleOrders, selectedDock) {
        if (!selectedDock.acceptableLocationCodes) {
            // console.log('selectedDock.acceptableLocationCodes is null or undefined')
            // console.log('selectedDock', selectedDock)
        }
        let acceptableLocationCodes = selectedDock.locationCodes.split(',')

        for (let shipment of allOrdersShipments) {
            let match = false
            if (shipment?.consignee?.id) {
                // is shipment.consignee.id in acceptableLocationCodes
                if (acceptableLocationCodes.includes(shipment.consignee.id)) {
                    match = true
                }
            }
            if (shipment?.shipper?.id) {
                // is shipment.shipper.id in acceptableLocationCodes
                if (acceptableLocationCodes.includes(shipment.shipper.id)) {
                    match = true
                }
            }

            // requested to be removed by kwiktrip oct 3 2023
            // if (shipment?.client?.id === 'kwiktrip' && shipment?.status?.code === 'IGNORE') {
            //     // Ignore Kwik Trip shipments (non managed shipments) where the status is set to IGNORE
            //     match = true
            // }

            if (!match) {

                let reason = { message: 'This order is not supposed to be scheduled at this dock.', offender: shipment.usercontrol }
                allOrdersValidatedMessage.push(reason)
                allOrdersValidated = false
            }
        }
    }
    isWrongDock(allOrdersShipments, multipleOrders, selectedDock)

    // Are there any orders that in shipment.details.mode has a value that is not LTL?
    // function isNotLTL(arrayOfObjects) {
    //     console.log('isNotLTL')
    //     let isNotLTLUCs = []
    //     for (const obj of arrayOfObjects) {
    //         console.log('obj.details.mode', obj.details.mode, obj.details.mode.length, obj.details.mode[0])
    //         if (obj.details.mode.length !== 1 || obj.details.mode[0] !== 'LTL') {
    //             isNotLTLUCs.push(obj.usercontrol)
    //         }
    //     }
    //     return isNotLTLUCs
    // }
    // let notLTLCheck = isNotLTL(allOrdersShipments)
    // if (notLTLCheck.length > 0) {
    //     allOrdersValidated = false
    //     let reason = { message: 'One or more orders are not LTL.', offender: notLTLCheck.join(', ') }
    //     allOrdersValidatedMessage.push(reason)
    // }

    return { allOrdersValidated, allOrdersValidatedMessage }
}

export default validateMultiOrderOneApptOrders