export const saveRruleCustomFields = async (dockEventId, currentlySelectedDate, data) => {
    // console.log('saveRruleCustomFields', dockEventId, date)
    var myHeaders = new Headers();

    myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
    myHeaders.append("content-type", "application/json;charset=UTF-8");

    let body = {
        dockEventId: dockEventId,
        date: currentlySelectedDate,
        data: data
    }

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: 'follow'
    };

    await fetch("https://api.shippersedge.com/schedule/recurringEventsFields/", requestOptions)
        .then(response => response.text())
        .then(result => {
            return result
        })
        .catch(error => console.log('error', error));
    return true

};

export default saveRruleCustomFields