import React, { useState } from 'react'
import moment from 'moment'
import { Modal, Button } from 'react-bootstrap'

const PickupStatus = ({ existingPickupEvent, pickupRequired, shipperLocationInfo, getDataNew, appointmentBeingScheduled }) => {


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if (appointmentBeingScheduled === 'Pickup') {
        return (
            <div className="alert alert-secondary border-dark mt-2 d-flex justify-content-center" role="alert">
                <strong>Updating ...</strong> <div className="spinner-border ms-3" role="status"></div>
            </div>
        )
    }

    if (existingPickupEvent.length) {
        return (
            existingPickupEvent.map(function (event, index) {
                return (
                    <div className="alert alert-success mt-2 border border-success" role="alert" key={index}>
                        Scheduled Pickup Appt:<br />
                        <strong>{moment(event.StartTime).format("MMMM D")}</strong><br />
                        <strong>{moment(event.StartTime).format("hh:mm a")}</strong> to <strong>{moment(event.EndTime).format("hh:mm a")}</strong><br />
                        <div className="d-flex flex-row-reverse">
                            <span className="link" onClick={handleShow}>Cancel</span>
                        </div>

                        <Modal show={show} onHide={handleClose}>
                            {/* <Modal.Header closeButton>
                                <Modal.Title>Cancel Pickup Appointment?</Modal.Title>
                            </Modal.Header> */}
                            <Modal.Body>Would you like to cancel the pickup appointment?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={() => { orchestrateDeleteDockAppts() }}>
                                    Yes
                                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                                    No
                                </Button>

                            </Modal.Footer>
                        </Modal>
                    </div>
                )
            })

        )
    } else if (pickupRequired) {
        return (
            <div className="alert alert-warning border border-warning mt-2" role="alert">Pickup appointment required.</div>
        )
    } else if (!pickupRequired) {
        return (
            <div className="alert alert-secondary border border-secondary mt-2" role="alert"><small>No pickup appointment required.</small></div>
        )
    } else {
        return (
            <div className="alert alert-warning border border-warning mt-2" role="alert">Error, unknown appointment status.</div>
        )
    }

    async function deleteDockSchedulerAppt(appointment) {
        console.log('DELETE', appointment)
        var myHeaders = new Headers();

        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
        myHeaders.append("content-type", "application/json;charset=UTF-8");


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(appointment),
            redirect: 'follow'
        };

        await fetch("https://api.shippersedge.com/schedule/shipment/", requestOptions)
            .then(response => response.text())
            .then(result => window.location.reload())
            .catch(error => console.log('error', error));
        return true
    }

    async function orchestrateDeleteDockAppts() {
        if (existingPickupEvent && existingPickupEvent.length) {
            existingPickupEvent.forEach(appointment => {
                console.log('WAT!?!?!')
                appointment.requestType = "eventRemove"
                appointment.responsibleUser = "carrier"
                appointment.locationId = shipperLocationInfo.dockLocationId
                // console.log('appt', appointment)
                deleteDockSchedulerAppt(appointment)

            });
        }
    }

}

export default PickupStatus
