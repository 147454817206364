export const permissionCheck = (userPerms, permissionToCheck) => {
    const debug = false
    debug && console.log('permissionCheck', userPerms, permissionToCheck)
    if (!Array.isArray(userPerms)) {
        debug && console.log('permissionCheck', false)
        return false
    }
    if (typeof permissionToCheck !== 'string') {
        permissionToCheck = permissionToCheck.toString()
    }
    if (userPerms.includes(permissionToCheck)) {
        debug && console.log('permissionCheck includes', true)
        return true
    } else {
        debug && console.log('permissionCheck includes', false)
        return false
    }
}