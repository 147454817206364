import React from 'react'

const ListSelectedSuppliers = ({ supplierWindowsMode, selectedSuppliers, setSelectedSuppliers }) => {

    if (!selectedSuppliers || !supplierWindowsMode) { return null }

    return (
        <div className="selectedSuppliers">
            <div className="row">
                <div className="col-12">
                    <h3>Selected Suppliers</h3>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Supplier</th>
                                <th scope="col">Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedSuppliers.map((supplier, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{supplier.name}</td>
                                        <td><button className="btn btn-sm btn-danger" onClick={() => {
                                            let newSelectedSuppliers = [...selectedSuppliers]
                                            newSelectedSuppliers.splice(index, 1)
                                            setSelectedSuppliers(newSelectedSuppliers)
                                        }}>Remove</button></td>
                                    </tr>
                                )
                            }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <input name="supplierRuleIds" type="text" className="hidden e-field e-input" value={selectedSuppliers.map(supplier => supplier.id).join(',')} />
        </div>

    )
}

export default ListSelectedSuppliers