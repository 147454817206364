import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import LoadingIcon from '../../../LoadingIcon/LoadingIcon'
import { UnScheduledShipmentCard } from '../UnScheduledShipmentCard/UnScheduledShipmentCard'

const UnScheduledShipments = ({ unScheduledShipments, toggleManagerMode }) => {

    const [unScheduledPickupShipmentsArray, setUnScheduledPickupShipmentsArray] = useState([])
    const [unScheduledDeliveryShipmentsArray, setUnScheduledDeliveryShipmentsArray] = useState([])
    const [pageMode, setPageMode] = useState('loading')

    useEffect(() => {
        if (unScheduledShipments) {
            getShipments()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unScheduledShipments])


    const getShipments = async () => {
        if (unScheduledShipments.pickup.length) {
            let pickups = await getShipmentsServiceV4(unScheduledShipments.pickup)
            let sortedPickups = pickups.data.sort((a, b) => a.shipper.dates.available1 - b.shipper.dates.available1)
            setUnScheduledPickupShipmentsArray(sortedPickups)
        }
        if (unScheduledShipments.delivery.length) {
            let delivery = await getShipmentsServiceV4(unScheduledShipments.delivery)
            let sortedDeliverys = delivery.data.sort((a, b) => a.consignee.dates.requestedDeliveryDate - b.consignee.dates.requestedDeliveryDate)
            setUnScheduledDeliveryShipmentsArray(sortedDeliverys)
        }
        setPageMode('running')
    }


    const getShipmentsServiceV4 = async (webqList) => {
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({ "webquotecontrols": webqList });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        return await fetch("https://api.shippersedge.com/v4/shipment/list", requestOptions)
            .then(response => response.text())
            .then(result => {
                // console.log("NO parsed", JSON.parse(result))
                // console.log("YES parsed", JSON.parse(result, reviveStringDatesToJSDates))
                return JSON.parse(result, reviveStringDatesToJSDates)
            })
            .catch(error => console.log('error', error));
    }

    function reviveStringDatesToJSDates(key, value) {
        // console.log('reviver', key, value)
        // eslint-disable-next-line no-mixed-operators
        if (typeof value === "string" && (/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d.\d\d\dZ$/.test(value) || key === "requestedDeliveryDate") || key === "available1" || key === "available2") {
            let dateNoTimezone = value + "T00:00:00"
            return new Date(dateNoTimezone);
        }

        return value;
    }


    // console.log('unScheduledShipments', unScheduledShipments)
    if (pageMode === 'loading') {
        return (
            <div className="card mt-3 mb-5" >
                <h3>Unscheduled Shipments</h3>
                <p>Pickup: {unScheduledShipments.pickup.length}</p>
                <p>Delivery: {unScheduledShipments.delivery.length}</p>
                <div className="d-flex flex-row justify-content-center mb-3"><LoadingIcon /></div>
            </div>
        )
    } else if (pageMode === 'error') {
        return (
            <div className="card mt-3 mb-5" >
                <h3>Unscheduled Shipments</h3>
                <p>Pickup: {unScheduledShipments.pickup.length}</p>
                <p>Delivery: {unScheduledShipments.delivery.length}</p>
                <div className="d-flex flex-row justify-content-center mb-3">Error.</div>
            </div>
        )
    } else if (pageMode === 'running') {
        return (
            <div className="card mt-3 mb-5" >
                <h1 className='mt-3'>Unscheduled Shipments</h1>

                <div className='row'>
                    {unScheduledShipments?.pickup && unScheduledShipments.pickup.length > 0 ?
                        (<div><h3>Pickup ({unScheduledShipments.pickup.length})</h3>
                            {unScheduledPickupShipmentsArray.map((shipment) => {
                                return (
                                    <React.Fragment key={shipment.id}>
                                        <UnScheduledShipmentCard shipment={shipment} mode={"pickup"} />
                                    </React.Fragment>
                                )
                            })}</div>)

                        : (
                            <div className='mb-5 mt-3'>
                                <h3>Pickup</h3>
                                <span>No unscheduled pickups at this time.</span>
                            </div>
                        )
                    }




                </div>
                <div className='row'>
                    {unScheduledShipments?.delivery && unScheduledShipments.delivery.length > 0 ? (<div>
                        <h3>Delivery ({unScheduledShipments.delivery.length})</h3>
                        <div className='d-flex flex-row flex-wrap'>
                            {unScheduledDeliveryShipmentsArray.map((shipment) => {
                                return (
                                    <React.Fragment key={shipment.id}>
                                        <UnScheduledShipmentCard shipment={shipment} mode={"delivery"} />
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>)
                        : (
                            <div>
                                <h3>Delivery</h3>
                                <span>No unscheduled deliveries at this time.</span>
                            </div>
                        )
                    }
                </div>

            </div>
        )
    } else {
        return (
            <div className="card mt-3 mb-5" >
                <h3>Unscheduled Shipments</h3>
                {/* <p>Pickup: {unScheduledShipments.pickup.length}</p>
                <p>Delivery: {unScheduledShipments.delivery.length}</p> */}
                <div>Unknown error.  Please contact support.</div>
            </div>
        )
    }
}

export default UnScheduledShipments
