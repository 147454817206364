const updateShipment = async (webquotecontrol, shipment) => {
    try {
        const url = `https://api.shippersedge.com/v4/shipment/${webquotecontrol}`
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW'
            },
            body: JSON.stringify(shipment)
        })
        if (!response.ok) {
            console.error('updateShipment.js error', response.statusText, response)
            throw Error(response.statusText)
        }
        try {
            let json = await response.json()
            return json
        } catch (err) {
            console.error('updateShipment.js error', err)
            return null
        }

    }
    catch (err) {
        console.error('updateShipment API Request failed', err)
        return null
    }
}

export default updateShipment