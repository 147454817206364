import React from 'react'

const USSCarrier = ({ shipment }) => {
    return (
        <>

            <span className='text-break text-wrap'>{shipment.carrier.name ? <>{shipment.carrier.name}</> : <>No Carrier Assigned</>}</span>
        </>
    )
}

export default USSCarrier
