export const getShipmentWebq = async (webquotecontrol, include) => {


    if (webquotecontrol === undefined) {
        console.error('Oh noes getShipmentWebq driverCode is undefined!')
    }



    let firstTry = await APICall(webquotecontrol, 10000)
    // console.log('getShipmentWebq first', firstTry)
    if (typeof firstTry === 'object' && firstTry !== null) {
        return firstTry
    } else {
        let secondTry = await APICall(webquotecontrol, 20000)
        // console.log('createChangeDeleteEventsAPI second', secondTry)
        if (typeof secondTry === 'object' && secondTry !== null) {
            return secondTry
        } else {
            let thridTry = await APICall(webquotecontrol, 45000)
            // console.log('createChangeDeleteEventsAPI third', thridTry)
            if (typeof thridTry === 'object' && thridTry !== null) {
                return thridTry
            } else {
                console.warn("getShipmentWebq saw an api call fail 3x")
                return 'failed'
            }
        }
    }

    async function APICall(webquotecontrol, timeOutValue) {

        return new Promise(function (resolve, reject) {

            let controller = new AbortController();

            let url =
                "https://api.shippersedge.com/v4/shipment/" + webquotecontrol + "/?include=" + include

            let myHeaders = new Headers();
            myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            let request = new Request(url, requestOptions);

            if (timeOutValue) {
                setTimeout(() => controller.abort(), timeOutValue);
            }


            fetch(request)
                .then((response) => {
                    // console.log(
                    //   "debug getShipmentWebq.js response",
                    //   response
                    // );
                    if (!response.ok) {
                        console.error(
                            "getShipmentWebq.js error",
                            response.statusText,
                            response
                        );
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((response) => {
                    // console.log('getShipmentWebq', response)
                    resolve(response);
                })
                .catch((err) => {
                    console.error("getShipmentWebq API Request failed", err);
                    resolve(err);
                });
        });
    }
};

export default getShipmentWebq