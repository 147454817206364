import React from 'react'
import moment from 'moment'
import ApptOption from './ApptOption/ApptOption'
import ApptOptionTwo from './ApptOptionTwo/ApptOptionTwo'
import { AttentionSeeker } from 'react-awesome-reveal';

const OpenApptWindowsView = ({ type, openTimeWindows, pickupOrDelivery, existingEvent, shipment, driverCode, getDataNew, adminDate, consolidatedOpenTimeWindows, handleApptBeingScheduled }) => {
    // console.log('openTimeWindows', adminDate, openTimeWindows)

    let consolidatedOpenTimeWindowsMode = true

    if (Object.keys(openTimeWindows).length === 0 && openTimeWindows.constructor === Object) {
        // console.log('openTimeWindows', openTimeWindows)
        return <div>No open appointments</div>
    }

    if (openTimeWindows && openTimeWindows.length === 0) {
        // console.log('openTimeWindows', openTimeWindows)
        return <div>No open appointments</div>
    } else if (!pickupOrDelivery) {
        // console.log('pickupOrDelivery', pickupOrDelivery)
        return <div>Unknown Pickup Mode</div>
    } else {
        if (pickupOrDelivery === 'Pickup') {
            if (consolidatedOpenTimeWindowsMode) {
                // console.log('consolidatedOpenTimeWindows.pickup', consolidatedOpenTimeWindows.pickup)

                return (breakoutConsolidatedAppts(type, consolidatedOpenTimeWindows.pickup, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled))
            } else {
                // console.log('openTimeWindows.pickup', openTimeWindows.pickup)
                // No longer used by anyone
                return (breakoutAppts(type, openTimeWindows.pickup, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled))
            }


        } else if (pickupOrDelivery === 'Delivery') {
            if (consolidatedOpenTimeWindowsMode) {
                // console.log('consolidatedOpenTimeWindows.delivery', breakoutConsolidatedAppts(type, consolidatedOpenTimeWindows.delivery, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled))
                return breakoutConsolidatedAppts(type, consolidatedOpenTimeWindows.delivery, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled)
            } else {
                // console.log('openTimeWindows.delivery', openTimeWindows.delivery)
                // No longer used by anyone
                return (breakoutAppts(type, openTimeWindows.delivery, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled))
            }


        } else {
            // console.log('pickupOrDelivery', pickupOrDelivery)
            return (
                <div>Unknown Pickup Mode 2</div>
            )
        }
    }
}
const breakoutConsolidatedAppts = (type, apptDates, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled) => {
    // console.log('openTimeWindows breakoutConsolidatedAppts', apptDates)
    let showDockName = false
    if (apptDates && apptDates.length > 0) {
        // random 6 digit number to use as a key for the accordion (in the case of consolidations there are multiple accordions, do not want the id to be the same)
        let randomKey = Math.floor(Math.random() * 1000000)
        // console.log('randomKey', randomKey)

        let options = apptDates.map((apptDate, index) => {
            let openOrClosed = ''
            let collapsed = 'collapsed'
            if (apptDates.length === 1) {
                openOrClosed = 'show'
                collapsed = ''
            }
            let availableAppts = 0
            apptDate.docks.forEach((dock) => {
                availableAppts = availableAppts + dock.options.length
            })
            let ariaExpanded = 'false'
            if (openOrClosed === 'show') {
                ariaExpanded = 'true'
            }

            return (
                <div key={index + randomKey} className="accordion accordion-flush ">
                    <div className="accordion-item border">
                        <h2 className="accordion-header" id={`headder_${index + randomKey}`}>
                            <button className={`accordion-button ${collapsed}`} type="button" data-bs-toggle="collapse" data-bs-target={`#target_${index + randomKey}`} aria-expanded={`target_${ariaExpanded}`} aria-controls={`target_${index + randomKey}`}>
                                <div className="d-flex justify-content-between w-100">
                                    <div>{moment(apptDate.date.replace(/-/g, '\/').replace(/T.+/, '')).format('dddd MMMM DD, YYYY')}</div>
                                    <div><span className="fw-lighter">({availableAppts} Appointments Available)&nbsp;&nbsp;&nbsp; </span></div>
                                </div>


                            </button>
                        </h2>

                        {/* Supplier Include rules / featured appts to encourage booking using appts generated by supplier rules that provide the supplier specific times based on their supplier id */}
                        <div id={`target_${index + randomKey}`} className={`accordion-collapse collapse ${openOrClosed}`} aria-labelledby={`target_${index + randomKey}`}>
                            <div className="accordion-body">
                                <div class="alert alert-warning border border-warning d-inline-flex align-items-center" role="alert">
                                    <div class="me-2 d-flex align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                                        </svg>
                                    </div>
                                    <div>
                                        The highlighted appointments are prioritized for your shipment. <br />
                                        Please consider selecting these first.
                                    </div>
                                </div>
                                {apptDate.docks.map((dock, index) => {
                                    dock.options.sort((a, b) => new Date(a.start) - new Date(b.start))
                                    let pastNoon = false
                                    return (
                                        <div className='d-flex flex-wrap justify-content-around'>
                                            {showDockName ? <span>{dock.name}</span> : null}
                                            {dock.options.map((option, index) => {
                                                if (option.supplierIncludeRule) {
                                                    return (
                                                        <AttentionSeeker key={index} effect='heartBeat'>
                                                            <ApptOptionTwo
                                                                key={index}
                                                                type={type}
                                                                option={option}
                                                                existingEvent={existingEvent}
                                                                shipment={shipment}
                                                                driverCode={driverCode}
                                                                getDataNew={getDataNew}
                                                                adminDate={apptDate.date}
                                                                handleApptBeingScheduled={handleApptBeingScheduled}
                                                                hilight={true}
                                                            />
                                                        </AttentionSeeker>
                                                    )
                                                }
                                            })}
                                        </div>
                                    )
                                })}

                            </div>
                            <hr />
                        </div>


                        {/* All other windows that are not supplier Includ rules */}
                        <div id={`target_${index + randomKey}`} className={`accordion-collapse collapse ${openOrClosed}`} aria-labelledby={`target_${index + randomKey}`}>
                            <div className="accordion-body">
                                {apptDate.docks.map((dock, index) => {
                                    dock.options.sort((a, b) => new Date(a.start) - new Date(b.start))
                                    let pastNoon = false
                                    return (
                                        <div className='d-flex flex-wrap justify-content-around'>
                                            {showDockName ? <span>{dock.name}</span> : null}
                                            {dock.options.map((option, index) => {
                                                if (!option.supplierIncludeRule) {
                                                    return (
                                                        <AttentionSeeker key={index} effect='pulse'>
                                                            <ApptOptionTwo
                                                                key={index}
                                                                type={type}
                                                                option={option}
                                                                existingEvent={existingEvent}
                                                                shipment={shipment}
                                                                driverCode={driverCode}
                                                                getDataNew={getDataNew}
                                                                adminDate={apptDate.date}
                                                                handleApptBeingScheduled={handleApptBeingScheduled}
                                                                hilight={false}
                                                            />
                                                        </AttentionSeeker>
                                                    )
                                                }
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                </div>
            )
        })
        // console.log('cat')
        return <div className="m-md-3 mb-3">{options}</div>
    } else {
        return (
            // bootstrap alert
            <div className='d-flex justify-content-center mt-3'>
                <div className="alert alert-secondary border border-dark rounded" role="alert" >
                    No appointments available at the specified date(s).
                </div>
            </div>

        )
    }
}
// No longer used by anyone
const breakoutAppts = (type, apptDates, existingEvent, shipment, driverCode, getDataNew, adminDate) => {
    console.warn('breakoutAppts is no longer used by anyone')
    // console.log('openTimeWindows breakoutAppts', apptDates)
    let showDockName = false
    if (apptDates) {

        let options = apptDates.map((apptDate, index) => {
            let openOrClosed = ''
            let collapsed = 'collapsed'
            if (apptDates.length === 1) {
                openOrClosed = 'show'
                collapsed = ''
            }
            let availableAppts = 0
            apptDate.docks.forEach((dock) => {
                availableAppts = availableAppts + dock.options.length
            })
            let ariaExpanded = 'false'
            if (openOrClosed === 'show') {
                ariaExpanded = 'true'
            }

            return (
                <div key={index} className="accordion accordion-flush ">
                    <div className="accordion-item border">
                        <h2 className="accordion-header" id={`headder_${index}`}>
                            <button className={`accordion-button ${collapsed}`} type="button" data-bs-toggle="collapse" data-bs-target={`#target_${index}`} aria-expanded={`target_${ariaExpanded}`} aria-controls={`target_${index}`}>
                                <div className="d-flex justify-content-between w-100">
                                    <div>{moment(apptDate.date.replace(/-/g, '\/').replace(/T.+/, '')).format('dddd MMMM DD, YYYY')}</div>
                                    <div><span className="fw-lighter">({availableAppts} Appointments Available)&nbsp;&nbsp;&nbsp; </span></div>
                                </div>


                            </button>
                        </h2>

                        <div id={`target_${index}`} className={`accordion-collapse collapse ${openOrClosed}`} aria-labelledby={`target_${index}`}>
                            <div className="accordion-body">
                                {apptDate.docks.map((dock, index) => {
                                    return (
                                        <div >

                                            {showDockName ? <span>{dock.name}</span> : null}
                                            {dock.options.map((option, index) => {
                                                return (
                                                    <ApptOption
                                                        key={index}
                                                        type={type}
                                                        option={option}
                                                        existingEvent={existingEvent}
                                                        shipment={shipment}
                                                        driverCode={driverCode}
                                                        getDataNew={getDataNew}
                                                        adminDate={adminDate}
                                                    />
                                                )
                                            })}

                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                </div>
            )
        })
        // console.log('cat', cat)
        return (<div className="m-md-3 mb-3">{options}</div>)
    } else {
        return (<p>No appointment dates 3</p>)
    }
}

export default OpenApptWindowsView