import React from 'react'


const EventTemplateWeek = (props, selectedDock,) => {
    // console.log('EventTemplateWeek', props)

    return (
        <div className="row text-dark">

            <div className="row text-dark">
                <div className="col-6">
                    {!props.approved ? <div style={{color: "red"}}><strong>Not Approved</strong></div> : null}
                    {props.usercontrol && props.usercontrol !== null ? <><span> {props.usercontrol}</span><br /></> : null}
                </div>
            </div>
            <div className="row">
                <div className="col-6">

                    {props.type === "Pickup" ?
                        (<><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right-square-fill" viewBox="0 0 16 16">
                            <path d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z" />
                        </svg></>) :
                        (<><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-left-square-fill" viewBox="0 0 16 16">
                            <path d="M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2zm8.096-10.803L6 9.293V6.525a.5.5 0 0 0-1 0V10.5a.5.5 0 0 0 .5.5h3.975a.5.5 0 0 0 0-1H6.707l4.096-4.096a.5.5 0 1 0-.707-.707z" />
                        </svg></>)}

                </div>
            </div>
            <div className="row">
                <span>{props.StartTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {props.EndTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span><br />
                {props.carriername ? <><span>{props.carriername}</span><br /> </> : null}
                {selectedDock.name === "All Docks" ? (<span>{props.dockName}</span>) : null}
            </div>
        </div>
    )
}

export default EventTemplateWeek
