// SelectedDateContext.js
import { createContext, useState, useContext } from 'react'

const SelectedDateContext = createContext()

export const useSelectedDate = () => {
    return useContext(SelectedDateContext)
}

export const SelectedDateProvider = ({ children }) => {
    const [selectedDate, setSelectedDate] = useState(null)

    return (
        <SelectedDateContext.Provider value={{ selectedDate, setSelectedDate }}>
            {children}
        </SelectedDateContext.Provider>
    )
}
