import React, { createContext, useState, useEffect, useContext } from 'react'

const UserPermissionsContext = createContext()

export const UserPermissionsProvider = ({ children }) => {
    const humanFriendlyDateTime = new Date().toLocaleString()
    // console.log('UserPermissionsProvider', humanFriendlyDateTime)
    const [userPerms, setUserPerms] = useState([])

    const fetchPermissions = async (cftoken) => {
        const response = await fetch(
            `https://api.shippersedge.com/authenticate/cfSession/?sessionId=${cftoken}`
        )
        const data = await response.json()

        if (data?.auth && data?.auth.userPerms) {
            // console.log('data.auth.userPerms', data)
            const newPermissions = data.auth.userPerms.split(",") // Splits the permissions string
            // Only set if permissions have changed
            if (JSON.stringify(newPermissions) !== JSON.stringify(userPerms)) {
                setUserPerms(newPermissions)
            }
        }
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const cftoken = urlParams.get('cftoken')
        if (cftoken) {
            fetchPermissions(cftoken)
        }

        const tenMinutes = 600000

        // Periodically refetch permissions
        const interval = setInterval(() => {
            if (cftoken) {
                fetchPermissions(cftoken)
            }
        }, tenMinutes)

        return () => clearInterval(interval)
    }, []) // Empty dependency array to run only on mount

    return (
        <UserPermissionsContext.Provider value={userPerms}>
            {children}
        </UserPermissionsContext.Provider>
    )
}

export const useUserPermissions = () => {
    return useContext(UserPermissionsContext)
}