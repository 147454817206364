import React from 'react'

const PoweredByFooter = () => {
    return (
        <>
            <hr className="border border-dark border-1 opacity-25 mt-4"></hr>
            <div className="mb-3 Titillium d-flex flex-row justify-content-center">

                <div className="d-flex justify-content-center align-items-center text-gray fs-3 me-3"><div>Powered By</div>
                </div> <div><a href="https://www.shippersedge.com/" target="_blank" rel='noreferrer'><img src={window.location.origin + '/shippersedge-logo.png'} height="55" alt="ShippersEdge TMS Logo"></img></a></div>
            </div>
        </>

    )
}

export default PoweredByFooter
