import React, { useState } from 'react'
import getRruleCustomFields from '../../../../utils/API/getRruleCustomFields'

const EventTemplateDayRRuleCustomFields = ({ props, localusercode, currentlySelectedDate }) => {
    // console.log('EventTemplateDayRRuleCustomFields', props, localusercode, currentlySelectedDate)
    let [rruleCustomFieldData, setRruleCustomFieldData] = useState(null)



    if (props.recurrenceRule) {
        // console.log('EventTemplateDayRRuleCustomFields', props.timeIn.slice(0, 10))
        // let date = props.timeIn.slice(0, 10)
        fetchData(props.id, currentlySelectedDate)
    }

    async function fetchData(dockEventId, date) {
        let result = await getRruleCustomFields(dockEventId, date)
        // console.log('EventTemplateDayRRuleCustomFields fetchData result', result)
        if (result && result?.data.length) {
            setRruleCustomFieldData(result.data[0].data)
        }
    }

    if (rruleCustomFieldData && props.RecurrenceRule) {

        return (
            <div onClick={() => fetchData(props.id, currentlySelectedDate)} style={{ cursor: 'pointer' }}>Order: {rruleCustomFieldData}</div>
        )
    } else if (props.RecurrenceRule) {
        // console.log('EventTemplateDayRRuleCustomFields NO-RENDER')
        return (
            <div onClick={() => fetchData(props.id, currentlySelectedDate)} style={{ cursor: 'pointer' }}>&nbsp;</div>
        )
    } else {
        return null
    }
}

export default EventTemplateDayRRuleCustomFields