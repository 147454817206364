import React from 'react'
// import './LoadingIcon.css'

const LoadingIcon = () => {

    return (
        <div className="loader60"></div>
    )
}

export default LoadingIcon
