// SearchResultsContext.js
import { createContext, useState, useContext } from 'react'

const SearchResultsContext = createContext()

export const useSearchResults = () => {
    return useContext(SearchResultsContext)
}

export const SearchResultsProvider = ({ children }) => {
    const [searchResults, setSearchResults] = useState([])
    const [searchState, setSearchState] = useState('start')

    return (
        <SearchResultsContext.Provider value={{ searchResults, setSearchResults, searchState, setSearchState }}>
            {children}
        </SearchResultsContext.Provider>
    )
}
