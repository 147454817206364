import React from 'react'

const LandingPage = () => {
    return (
        <div className="container">
            <header className="App-header">
                <a href="https://www.shippersedge.com/"><img src="shippersedge-logo.png" alt="logo" height="200px" /></a>
                <span className="TitleText mb-3 mt-3">Dock Scheduler</span>

                <br />
            </header>
        </div>
    )
}

export default LandingPage
