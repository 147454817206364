import { useState, useEffect } from 'react';
import getCarrierView from '../../../utils/API/getCarrierView';


const useCarrier = (props) => {

    const [urlParams, setUrlParams] = useState(props)
    const [shipment, setShipment] = useState(null)
    const [driverCode, setDriverCode] = useState(null)
    const [pickupOrDelivery, setPickupOrDelivery] = useState(null)
    const [pickupRequired, setPickupRequired] = useState(false)
    const [deliveryRequired, setDeliveryRequired] = useState(false)


    // Page mode determines if the cal displays / error status and etc.
    const [pageMode, setPageMode] = useState('loading')
    const [errorText, setErrorText] = useState(null)

    const [locationInfo, setLocationInfo] = useState(null)
    const [shipperLocationInfo, setShipperLocationInfo] = useState(null)
    const [consigneeLocationInfo, setConsigneeLocationInfo] = useState(null)

    // const [pickupEvents, setPickupEvents] = useState(null)
    // const [deliveryEvents, setDeliveryEvents] = useState(null)

    const [delMinMaxSelectedDates, setDelMinMaxSelectedDates] = useState(null)
    const [shipMinMaxSelectedDates, setShipMinMaxSelectedDates] = useState(null)

    const [existingPickupEvent, setExistingPickupEvent] = useState([])
    const [existingDeliveryEvent, setExistingDeliveryEvent] = useState([])

    const [hours, setHours] = useState(null)
    const [startHour, setStartHour] = useState(null)
    const [endHour, setEndHour] = useState(null)

    const [dockEventsPickup, setDockEventsPickup] = useState(null)
    const [dockEventsDelivery, setDockEventsDelivery] = useState(null)

    const [carrierViewQueryString, setCarrierViewQueryString] = useState(null)

    const [adHocMode, setAdHocMode] = useState(false)

    const [openTimeWindows, setOpenTimeWindows] = useState([])

    const [carrierView, setCarrierView] = useState(null)

    const [adminDate, setAdminDate] = useState(null)

    const [consolidatedOpenTimeWindows, setConsolidatedOpenTimeWindows] = useState([])


    useEffect(() => {
        getDataNew(driverCode)
    }, [adminDate, driverCode])// eslint-disable-line


    // Action starts here
    useEffect(() => {
        if (props.driverCode !== null) {
            startUp()
        }
    }, [urlParams]) // eslint-disable-line


    useEffect(() => {
        if (driverCode) {
            // console.log('------------------------------------------------------')
            // console.log('useCarrier() useEffect() driverCode called', driverCode)
            // console.log('------------------------------------------------------')
            // getData(driverCode)
            getDataNew(driverCode)
        }
    }, [driverCode])// eslint-disable-line

    // DockEvents Monitor
    // useEffect(() => {
    //     if (dockEvents) {
    //         // // getData(driverCode)
    //         // console.log('pickupEvents Changing?', pickupEvents.length)
    //         // console.log('deliveryEvents Changing?', deliveryEvents.length)
    //         // // console.log('carrierView pickupEvents Changing?', carrierView.events.pickup.length)
    //         // // console.log('carrierView deliveryEvents Changing?', carrierView.events.delivery.length)
    //         // console.log('carrierview STRING deliveryEvents Changing?', JSON.parse(carrierViewString).events.delivery.length)
    //     }
    // }, [dockEvents])// eslint-disable-line





    useEffect(() => {
        if (pageMode === 'running') {
            changeMode()

            // console.log('dockEvents length', dockEvents.length)
            // console.log('dockEvents SELECTED', dockEvents)
        }
    }, [pickupOrDelivery, pageMode])// eslint-disable-line


    async function changeMode() {
        let cv = JSON.parse(carrierView)
        let eventsJSDates = await reviveEventsDates(cv.events)
        cv.events = eventsJSDates
        if (pickupOrDelivery === "Pickup") {
            setStartHour(cv.startAndEndHours.pickupStartHour)
            setEndHour(cv.startAndEndHours.pickupEndHour)
        }
        if (pickupOrDelivery === "Delivery") {
            setStartHour(cv.startAndEndHours.deliveryStartHour)
            setEndHour(cv.startAndEndHours.deliveryEndHour)
        }
    }



    useEffect(() => {
        if (carrierView) {
            // getData(driverCode)
            processCarrierView()
        }
    }, [carrierView])// eslint-disable-line

    // This only runs on startup 
    async function startUp() {
        setDriverCode(props.driverCode)
        // Checking for adhoc mode or other params passed here
        // We pass these on directly to getCarrierView as a string to allow for different modes and data to flow through the SE interface to the DS front end to the DS back end
        // let queryParamsString = window.location.search;
        // let params = new URLSearchParams(queryParamsString);
        // let apptMode = params.get('mode');
        // if (apptMode === 'adhoc') {
        //     setCarrierViewQueryString(queryParamsString)
        //     setAdHocMode(true)
        // }

    }

    async function getDataNew(driverCode) {

        if (driverCode === null) { return false }

        let extraQueryString = ''

        if (adminDate) {
            // console.log('AdminDatePicker getDataNew', adminDate)
            extraQueryString += '?adminDate=' + adminDate
        }

        let CV = await getCarrierView(driverCode, extraQueryString)
        // console.log('CV', CV)
        if (CV instanceof Error) {
            console.log("---------------------")
            console.error("OH NOES ERROR GETTING CARRIER VIEW")
            console.log(CV)
            console.log("---------------------")
            // setPageMode('error')
            // setErrorText('Error in getCarrierView.')
            // return false
        } else {
            // let eventsWithJSDates = await reviveEventsDates(CV.events)
            // CV.events = eventsWithJSDates
            if (CV?.status && CV.status === "success") {
                setCarrierView(JSON.stringify(CV))
            }
            if (CV?.status && CV.status === "error") {
                setPageMode('error')
                if (CV?.errorStatus) {
                    setErrorText(CV.errorStatus)
                }
            }
        }
    }


    // Dates from the carriverView call come in as strings, need to convet them to JavaScript dates here.
    async function reviveEventsDates(events) {
        let eventsOBJ = {}
        let newPickupEventsWithJSDates = []
        for (let event of events.pickup) {
            event.StartTime = new Date(event.StartTime)
            event.EndTime = new Date(event.EndTime)
            newPickupEventsWithJSDates.push(event)
        }
        let newDeliveryEventsWithJSDates = []
        for (let event of events.delivery) {
            event.StartTime = new Date(event.StartTime)
            event.EndTime = new Date(event.EndTime)
            newDeliveryEventsWithJSDates.push(event)
        }
        eventsOBJ.pickup = newPickupEventsWithJSDates
        eventsOBJ.delivery = newDeliveryEventsWithJSDates
        return eventsOBJ
    }

    async function processCarrierView() {
        // console.log('processCarrierView()')
        let cv = JSON.parse(carrierView)
        let eventsJSDates = await reviveEventsDates(cv.events)
        cv.events = eventsJSDates
        setHours(cv.startAndEndHours)
        let existingPickupAppt = cv.existingAppts.filter(event => event.type === "Pickup")
        if (existingPickupAppt.length) {
            setExistingPickupEvent(existingPickupAppt)
        }
        let existingDeliveryAppt = cv.existingAppts.filter(event => event.type === "Delivery")
        if (existingDeliveryAppt.length) {
            setExistingDeliveryEvent(existingDeliveryAppt)
        }
        setShipMinMaxSelectedDates(cv.calendarPickupDates)
        setDelMinMaxSelectedDates(cv.calendarDeliveryDates)
        setPickupRequired(cv.appointmentRequired.pickup)
        setDeliveryRequired(cv.appointmentRequired.delivery)
        setDockEventsPickup(cv.events.pickup)
        setDockEventsDelivery(cv.events.delivery)
        setShipperLocationInfo(cv.shipperLocationInfo)
        setConsigneeLocationInfo(cv.consigneeLocationInfo)
        setOpenTimeWindows(cv.openTimeWindows)
        setConsolidatedOpenTimeWindows(cv.consolidatedOpenTimeWindows)

        // Determine type of apptment to start with (pickup or delivery), also checking if adhoc mode is being used and settingthe type by that
        if (cv.appointmentRequired.pickup) {
            setPickupOrDelivery('Pickup')
            setLocationInfo(cv.shipperLocationInfo)
            setStartHour(cv.startAndEndHours.pickupStartHour)
            setEndHour(cv.startAndEndHours.pickupEndHour)
        } else if (cv.appointmentRequired.delivery) {
            setPickupOrDelivery('Delivery')
            setLocationInfo(cv.consigneeLocationInfo)
            setStartHour(cv.startAndEndHours.deliveryStartHour)
            setEndHour(cv.startAndEndHours.deliveryEndHour)
        } else if (adHocMode) {
            let params = new URLSearchParams(carrierViewQueryString);
            let apptType = params.get('type');
            if (apptType === "pickup") {
                setPickupOrDelivery('Pickup')
                setLocationInfo(cv.shipperLocationInfo)
                setStartHour(cv.startAndEndHours.pickupStartHour)
                setEndHour(cv.startAndEndHours.pickupEndHour)
            } else if (apptType === "delivery") {
                setPickupOrDelivery('Delivery')
                setLocationInfo(cv.consigneeLocationInfo)
                setStartHour(cv.startAndEndHours.deliveryStartHour)
                setEndHour(cv.startAndEndHours.deliveryEndHour)
            }
        }


        setShipment(cv.shipment)
        setPageMode('running')
    }


    return { pageMode, errorText, driverCode, shipment, setPickupOrDelivery, pickupOrDelivery, pickupRequired, deliveryRequired, locationInfo, startHour, endHour, delMinMaxSelectedDates, shipMinMaxSelectedDates, existingPickupEvent, existingDeliveryEvent, getDataNew, dockEventsPickup, dockEventsDelivery, shipperLocationInfo, consigneeLocationInfo, openTimeWindows, adminDate, setAdminDate, consolidatedOpenTimeWindows }
}

export default useCarrier