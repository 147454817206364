import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const AdminDatePicker = ({ shipment, setAdminDate }) => {
    const [date, setDate] = useState('')
    const [initialAdminDate, setInitialAdminDate] = useState(shipment.client.id === 'kwiktrip' ? shipment.consignee?.dates?.requestedDeliveryDate?.slice(0, 10) : '')
    const [inputMinDate, setInputMinDate] = useState(new Date().toISOString().split("T")[0])
    const changeDate = (date) => {
        setInitialAdminDate(date)
        setDate(date)
    }
    let url = window.location.search
    let adminString = "adminMode=1337"

    // For Kwik Trip Distribution Center (Produce) dock no appointments before the requested delivery date
    useEffect(() => {
        if (shipment?.consignee?.id === 'kwiktrip711') {
            setInputMinDate(shipment.consignee?.dates?.requestedDeliveryDate?.slice(0, 10))
        }
    }, [shipment])

    if (shipment && (url.includes(adminString) || shipment.client.id === 'kwiktrip' || shipment.client.id === 'sleepcountry')) {
        return (
            <Row>
                <Col md={12} xs={12}>
                    <div className="alert alert-info" role="alert">
                        Select an alternate date:<br />
                        <Row>
                            <Col md={8} xs={7}>
                                <input type="date" min={inputMinDate} className="form-control" value={initialAdminDate} onChange={(event) => { changeDate(event.target.value) }} />
                            </Col>
                            <Col md={4} xs={5}>
                                <button className="btn btn-secondary" onClick={() => { setAdminDate(date) }}>Change</button>
                            </Col>
                        </Row>
                    </div>

                </Col>
                <Col md={4} xs={0} />
            </Row>
        )
    } else { return null }
}

export default AdminDatePicker