import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ReferenceNumbersCustomFields = ({ shipment }) => {
    // Add custom field ids here to quickly add customer custom fields to the reference numbers section
    const customFieldsToDisplay = [212]

    // console.log('shipment ReferenceNumbersCustomFields', shipment)
    let render = false

    if (shipment.customFields) {
        shipment.customFields.forEach(customField => {
            if (customFieldsToDisplay.includes(customField.fieldID)) {
                render = true
            }
        })
    }
    // console.log('shipment ReferenceNumbersCustomFields', render)

    if (render) {
        return (
            <div>
                {shipment.customFields.map((customField, index) => {
                    if (customFieldsToDisplay.includes(customField.fieldID)) {
                        return (
                            <React.Fragment key={index} >
                                {customField.fieldLabel} <span className="linkText noExpand" >{customField.fieldValue}</span><br />
                            </React.Fragment>
                        )
                    }
                }
                )}
            </div>
        )
    } else {
        return null
    }

}

export default ReferenceNumbersCustomFields