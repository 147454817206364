export const getAndSetMultipleShipments = async (webqArray, setMultiShipmentApptModeOrders) => {
    let promisesArray = []
    for (let webq of webqArray) {

        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let webquotecontrol = webq
        let shipment = await fetch("https://api.shippersedge.com/v4/shipment/" + webquotecontrol + '/?include=all', requestOptions)
            .then(response => response.text())
            .then(result => {
                return (JSON.parse(result))

            })
            .catch(error => {
                console.error('ApptEditorTemplate getAndSetMultipleShipments error', error)
                return false
            })
        promisesArray.push(shipment)
    }
    let results = await Promise.all(promisesArray)
    setMultiShipmentApptModeOrders(results)

}

export default getAndSetMultipleShipments