export const deriveStopInfoFromConsolidationWithOrder = (consolidationShipment, orderWebquotecontrol) => {
    console.log('deriveStopInfoFromConsolidationWithOrder', consolidationShipment, orderWebquotecontrol)
    let stops = {}
    let validPickupStops = consolidationShipment.stops.filter(stop => stop.type === 'Pickup')
    let thePickupStopForThisOrder = null
    for (const stop of validPickupStops) {
        for (const order of stop.orders) {
            if (parseInt(order.id) === orderWebquotecontrol) {
                thePickupStopForThisOrder = stop
            }
        }
    }
    if (thePickupStopForThisOrder) {
        stops.pickup = thePickupStopForThisOrder
    }
    let validDeliveryStops = consolidationShipment.stops.filter(stop => stop.type === 'Delivery')
    let theDeliveryStopForThisOrder = null
    for (const stop of validDeliveryStops) {
        for (const order of stop.orders) {
            if (parseInt(order.id) === orderWebquotecontrol) {
                theDeliveryStopForThisOrder = stop
            }
        }
    }
    if (theDeliveryStopForThisOrder) {
        stops.delivery = theDeliveryStopForThisOrder
    }
    return stops
}

export default deriveStopInfoFromConsolidationWithOrder