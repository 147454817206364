import React, { useState, useEffect } from 'react'
// import moment from 'moment'
import { RecurrenceEditorComponent } from '@syncfusion/ej2-react-schedule';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import ValidatedField from './SubComponents/ValidatedField';
import ApptEditorTemplateShipmentDetails from './ApptEditorTemplateComponents/ApptEditorTemplateShipmentDetails'
import ApptEditorTemplateRruleCustomFields from './ApptEditorTemplateComponents/ApptEditorTemplateRruleCustomFields';
import {
    // datetime, 
    RRule,
    // RRuleSet, 
    // rrulestr 
} from 'rrule'
import DropLiveShipment from '../../CommonSmallComponents/DropLiveShipment';
import { getConsolidatedShipment } from '../../../utils/helpers/getConsolidatedShipment'
import { validateMultiOrderOneApptOrders } from '../../../utils/helpers/validateMultiOrderOneApptOrders'
import ApptEditorMultiOrderValidationUI from './ApptEditorTemplateComponents/ApptEditorMultiOrderValidationUI';
import getAndSetMultipleShipments from '../../../utils/helpers/getAndSetMultipleShipments';
import ApprovedAppt from '../../CommonSmallComponents/ApprovedAppt';
import SupplierWindows from './SupplierWindows/SupplierWindows';
import InOutTimes from './InOutTimes';
import Colors from './Colors';
import { useUserPermissions } from '../../../context/UserPermissionsProvider';
import { permissionCheck } from '../../../utils/helpers/permissionCheck';


const ApptEditorTemplate = (props, selectedDock, docks, localusercode, currentlySelectedDate, ownerData) => {
    // When clicking save on the editor window the fields must have a name and classnames of 'e-field e-input' in order for the data to be passed along to actionBegin in Manager.js, if you don't you'll get an error "Failed to execute 'closest' on 'Element'"
    //
    // NOTE:  Syncfusion sets the values of the fields with the class "e-fields" based ... and some unknown process that isn't reflected in props.   You can't set e-field values by simply by setting value="Here is a value".   To set a value that value must be set back in DocksManagerSchedules.js in onCellClick() and alter the args values that eventaully make it to ApptEditorTemplate.   BUT Syncfusion won't set just any field when setting it that way either... it's not entirely clear how that works to me yet.
    //
    // Editor template tips:
    // https://ej2.syncfusion.com/documentation/schedule/editor-template/ 
    // https://www.youtube.com/watch?v=r24VBNlUmGg
    // Form validation requirements:
    // https://www.syncfusion.com/forums/170117/not-all-required-fields-are-validated-with-when-using-editortemplate-validator-seems-to

    const userPerms = useUserPermissions()


    let recObject;

    let [validated, setValidated] = useState(false)
    let [validationMessage, setValidationMessage] = useState(false)
    let [ucValue, setUCValue] = useState(0)
    let [multipleOrders, setMultipleOrders] = useState([])
    let [shipmentDetails, setShipmentDetails] = useState(null)
    let [isConsolidation, setIsConsolidation] = useState(false)
    let [consolidationShipment, setConsolidationShipment] = useState(null)
    let [multiShipmentApptMode, setMultiShipmentApptMode] = useState(false)
    let [multiShipmentApptValidationMode, setMultiShipmentApptValidationMode] = useState(false)
    let [multiShipmentApptModeOrders, setMultiShipmentApptModeOrders] = useState([])
    let [multiShipmentApptValidationResults, setMultiShipmentApptValidationResults] = useState([])
    let [allOrdersValidatedMessage, setAllOrdersValidatedMessage] = useState([])
    let [recurrenceStyleForHiding, setRecurrenceStyleForHiding] = useState({})
    let [multiShipmentApptModeOrdersNoValidation, setMultiShipmentApptModeOrdersNoValidation] = useState([])
    let [supplierWindowsMode, setSupplierWindowsMode] = useState(false)

    // console.log('ApptEditorTemplate props', props, props.id)
    let multiShipmentApptModeProps = false
    if (props?.webquotecontrol && typeof props.webquotecontrol === 'string' && props.webquotecontrol.includes(',')) {
        multiShipmentApptModeProps = true
    }

    useEffect(() => {
        // Existing appointment for a multi order (multi shipment) appointment
        if (multiShipmentApptModeProps) {
            // console.log('ApptEditorTemplate multiOrderSingleAppt props.webquotecontrol', props.webquotecontrol)
            let orderList = props.webquotecontrol.replace(/\s/g, '').split(',')
            // console.log('ApptEditorTemplate multiOrderSingleAppt orderList', orderList)
            getAndSetMultipleShipments(orderList, setMultiShipmentApptModeOrdersNoValidation)
        }
    }, [])

    useEffect(() => {
        // After ShipmentDetails is populated need to check if this shipment is a consolidation shipment 
        if (shipmentDetails && shipmentDetails?.details?.trip) {
            // console.log('ApptEditorTemplate oh man better check if this is a consolidaton shipment')
            setIsConsolidation(true)
            // getConsolidatedShipment sets the cconsolidation shipment to state
            getConsolidatedShipment(shipmentDetails.details.trip, shipmentDetails?.id, setConsolidationShipment)

        }
    }, [shipmentDetails, props.webquotecontrol, props.id])

    useEffect(() => {
        // console.log('yo', consolidationShipment)
    }, [consolidationShipment])

    if (!props) { return null }

    if (props) {
        // console.log('ApptEditorTemplate props', props)

        let kwiktripMode = false
        let kwiktripVisiblityHidden = {}
        if (localusercode === 'kwiktrip') {
            kwiktripMode = true
            kwiktripVisiblityHidden = { visibility: 'hidden', maxHeight: 0 }
        }


        let ruleText
        if (props.RecurrenceRule && props.RecurrenceRule !== "") {
            handleRecurrenceRules(props)
        } else {
            // Have to put the save button back if for some reson it was hidden by handleRecurrenceRules previously.
            // No idea why this is required as this is a function that should run and render each time but there you go
            let savebtn = document.getElementsByClassName('e-event-save')
            savebtn[0].style.display = ""
        }

        let debug = false


        if (props.webquotecontrol && !shipmentDetails && !multiShipmentApptModeProps) {
            getShipmentDetails()
        }

        async function validateUsercontrol(event) {
            clearShipmentId()
            hideRecurrenceEditor()

            if (!event.target.value) {
                // user backspaced to nothing in the uc/order field
                setUCValue(event.target.value.length)
                setMultiShipmentApptMode(false)
                setMultiShipmentApptValidationMode(true)
                setMultipleOrders([])
                setMultiShipmentApptModeOrders([])
                setMultiShipmentApptValidationResults([])
                setAllOrdersValidatedMessage([])
                setMultiShipmentApptModeOrdersNoValidation([])
                enableSaveButton()
                return true
            }

            if (event.target.value) {
                setUCValue(event.target.value.length)
            }

            let multiShipmentOneApptMode = false
            if (event.target.value.includes(',')) {
                multiShipmentOneApptMode = true
            } else {
                setMultiShipmentApptMode(false)
            }


            // Most shipments go here
            if (!multiShipmentOneApptMode) {
                // This is where the action begins for multiple seperate orders assigned to one appointment is.
                setMultiShipmentApptMode(true)
                // remove spaces and split into an array on comma
                let orderList = event.target.value.replace(/\s/g, '').split(',')
                setMultipleOrders(orderList)
                setMultiShipmentApptValidationMode(true)
                setShipmentDetails(null)
                setValidated(false)
                setValidationMessage('Enter all orders and then click the validate button to validate a list of orders.')
                disableSaveButton()
                // User hits the validate multiple orders button and validateMultipleOrders() fires
            }

            if (multiShipmentOneApptMode) {
                // This is where the action begins for multiple seperate orders assigned to one appointment is.
                setMultiShipmentApptMode(true)
                // remove spaces and split into an array on comma
                let orderList = event.target.value.replace(/\s/g, '').split(',')
                setMultipleOrders(orderList)
                setMultiShipmentApptValidationMode(true)
                setShipmentDetails(null)
                setValidated(false)
                setValidationMessage('Enter all orders and then click the validate button to validate a list of orders.')
                disableSaveButton()
                // User hits the validate multiple orders button and validateMultipleOrders() fires
            }

        }

        async function validateMultipleOrders(multipleOrders, selectedDock) {

            // send the array to the shipment service and see what comes back
            let allOrdersShipments = []
            for (const order of multipleOrders) {

                let url = 'https://api.shippersedge.com/v4/shipment/UC/' + order + '/?usercode=' + localusercode + '&include=all'

                var myHeaders = new Headers()
                myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW")

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                let shipment = await fetch(url, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        // console.log('validateMultipleOrders result', result)
                        return result
                    })
                    .catch(error => {
                        console.error('ApptEditorTemplate validateUsercontrol error', error)
                        return {}
                    })
                allOrdersShipments.push(shipment)
            }
            let existingApptId
            if (props.id) {
                existingApptId = props.id
            }
            let { allOrdersValidated, allOrdersValidatedMessage } = await validateMultiOrderOneApptOrders(allOrdersShipments, multipleOrders, existingApptId, selectedDock)
            setMultiShipmentApptModeOrders(allOrdersShipments)
            setMultiShipmentApptValidationResults(allOrdersValidatedMessage)
            setAllOrdersValidatedMessage(allOrdersValidatedMessage)
            if (!allOrdersValidated) {
                disableSaveButton()
            } else {
                enableSaveButton()
            }
        }

        function populateShipmentId(shipmentId) {
            let shipmentIdElement = document.getElementById('shipmentId')
            // console.log('ApptEditorTemplate1 shipmentIdElement', shipmentIdElement)
            shipmentIdElement.innerHTML = shipmentId
        }

        function clearShipmentId() {
            let shipmentIdElement = document.getElementById('shipmentId')
            shipmentIdElement.innerHTML = ''
        }


        async function getShipmentDetails(value) {
            if (props.webquotecontrol || value) {
                var myHeaders = new Headers();
                myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                let webquotecontrol
                if (value) {
                    webquotecontrol = value
                } else {
                    webquotecontrol = props.webquotecontrol
                }
                fetch("https://api.shippersedge.com/v4/shipment/" + webquotecontrol + '/?include=all', requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        setShipmentDetails(JSON.parse(result))
                    })
                    .catch(error => {
                        console.error('ApptEditorTemplate getShipmentDetails error', error)
                    });
            }
            return false
        }

        function handleRecurrenceRules(rruleFromProps) {
            // console.log(props.RecurrenceRule)
            let ruleString = props.RecurrenceRule
            ruleText = "Default rule text."
            if (ruleString.endsWith(';')) {
                ruleString = ruleString.slice(0, -1)
            }
            try {
                let rule = RRule.fromString(ruleString)
                // console.log('rule ', rule.toText())
                ruleText = 'Starting ' + props.StartTime.toDateString()
                ruleText += ', occuring ' + rule.toText()

                ruleText += '.'

            } catch {
                console.log('rule error')
            }
            let savebtn = document.getElementsByClassName('e-event-save')
            savebtn[0].style.display = "none"
        }

        function disableSaveButton() {
            let savebtn = document.getElementsByClassName('e-event-save')
            for (const button of savebtn) {
                button.disabled = true
            }
            return true
        }
        function enableSaveButton() {
            let savebtn = document.getElementsByClassName('e-event-save')
            for (const button of savebtn) {
                button.disabled = false
            }
            return true
        }

        function hideRecurrenceEditor() {
            setRecurrenceStyleForHiding({ visibility: 'hidden', maxHeight: 0 })
        }

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        function noShipmentsAssignedToRecurrenceEvents() {
            clearShipmentId()
            if (shipmentDetails) { setShipmentDetails(null) }
            if (validated) { setValidated(false) }
            if (ucValue !== 0) { setUCValue(0) }
            let ucInput = document.getElementById('usercontrol')
            ucInput.value = ""
            ucInput.disabled = true
        }


        let fields = { text: 'OwnerText', value: 'id' };
        return (
            <div className="container">

                {/* Attempt to add the owner data to resolve the validator error here but adding it did not help */}
                {/* https://ej2.syncfusion.com/react/documentation/schedule/editor-template/#how-to-change-the-editor-window-header-title-and-text-of-footer-buttons look for "How to add resource options within editor template" */}

                {debug ? (<span>usercode is: {localusercode}, {props.id}</span>) : null}
                {debug && kwiktripMode ? (<span>KwikTripMode!</span>) : null}

                <div className="row mb-3">
                    {props.carriername ?
                        <div className="col-sm-6">
                            <label htmlFor="carrier">Carrier:</label>
                            <div id="carrier">{props.carriername}</div>
                        </div>
                        : null}
                </div>
                {(props.webquotecontrol || shipmentDetails) && !multiShipmentApptModeOrdersNoValidation.length ? <ApptEditorTemplateShipmentDetails shipment={shipmentDetails} isConsolidation={isConsolidation} consolidationShipment={consolidationShipment} appointmentInfo={props} multiShipmentApptMode={multiShipmentApptMode} /> : null}

                {(multiShipmentApptValidationMode && multiShipmentApptModeOrders.length) && !multiShipmentApptModeOrdersNoValidation.length ? <ApptEditorMultiOrderValidationUI multiShipmentApptModeOrders={multiShipmentApptModeOrders} multiShipmentApptValidationResults={multiShipmentApptValidationResults} allOrdersValidatedMessage={allOrdersValidatedMessage} /> : null}

                {multiShipmentApptModeOrdersNoValidation.length ? <ApptEditorMultiOrderValidationUI multiShipmentApptModeOrders={multiShipmentApptModeOrdersNoValidation} multiShipmentApptValidationResults={[]} allOrdersValidatedMessage={[]} noValidationUI={true} /> : null}

                <div className="row mb-3">
                    <div className="col-sm-6">
                        <label htmlFor="StartTime" className="form-label col-form-label-sm">Start:</label>
                        {!props.RecurrenceRule ? (
                            <DateTimePickerComponent
                                id="StartTime"
                                data-name="StartTime"
                                className="e-field"
                                value={props.StartTime}

                            />) :
                            (
                                <span><br />{props.StartTime.toDateString()} {props.StartTime.toLocaleTimeString()}</span>
                            )}
                    </div>

                    <div className="col-sm-6">
                        <label htmlFor="EndTime" className="form-label col-form-label-sm">End:</label>
                        {!props.RecurrenceRule ? (
                            <DateTimePickerComponent
                                id="EndTime"
                                data-name="EndTime"
                                className="e-field"
                                value={props.EndTime}
                            />) : (
                            <span><br />{props.EndTime.toDateString()} {props.EndTime.toLocaleTimeString()}</span>
                        )}
                    </div>

                </div>
                <div className="row mb-3">
                    <div className="col-sm-6 input-group input-group-sm">
                        <label htmlFor="dockId" className="input-group form-label col-form-label-sm">Dock:</label>
                        {!props.RecurrenceRule ? (
                            <DropDownListComponent
                                id="dockId"
                                className="e-field"
                                aria-label="dockId"
                                data-name="dockId"
                                dataSource={ownerData}
                                fields={fields}
                                value={props.dockId || null}

                            ></DropDownListComponent>)
                            :
                            (<span className='mb-3'><strong>{props.dockName}</strong></span>)

                        }
                    </div>
                    {/* visiblity hidden for kissern */}
                    <div className="col-sm-6 input-group input-group-sm" style={kwiktripVisiblityHidden}>
                        {!props.RecurrenceRule ? (
                            <>
                                <label htmlFor="type" className="input-group form-label col-form-label-sm "></label>
                                <select id="type" name="type" className="custom-select e-field e-input" aria-label="type"
                                    disabled={kwiktripMode} >
                                    <option value="Pickup">Pickup</option>
                                    <option value="Delivery">Delivery</option>

                                </select>
                            </>
                        )

                            : (

                                <>
                                    <span><strong>{props.type}</strong></span>
                                </>
                            )}

                    </div>
                    <div className="col-sm-10 input-group input-group-sm d-flex" >
                        {kwiktripMode || localusercode === 'sleepcountry' ?
                            <DropLiveShipment role={'manager'} shipment={shipmentDetails}
                            /> : null}
                        {kwiktripMode && props.webquotecontrol ? <ApprovedAppt role={'manager'} appointment={props} /> : null}
                        {(kwiktripMode || permissionCheck(userPerms, 1560)) && props.webquotecontrol ? <Colors shipment={shipmentDetails} appointmentProps={props} /> : null}
                    </div>

                </div>
                <div className="row mb-3">
                    <div className="col-sm-6">
                        {/* Field names description and Subject get flip flopped, that is resolved in DocksManagerSchedules actionBegin() */}
                        <label htmlFor="description" className="form-label col-form-label-sm">Description:</label><br />
                        {!props.RecurrenceRule ?
                            (<textarea
                                className="form-control form-control-sm e-field e-input"
                                id="description"
                                name="description"
                                aria-label="description"
                                data-name="description"
                                rows="6"

                            ></textarea>) :

                            (<textarea
                                className="form-control form-control-sm e-field e-input"
                                id="description"
                                name="description"
                                aria-label="description"
                                data-name="description"
                                rows="6"
                                disabled
                            ></textarea>)
                        }

                    </div>
                    <div className="col-sm-3 text-break">
                        {!props.RecurrenceRule && !supplierWindowsMode ?
                            <>
                                <label htmlFor="shipmentId" className="form-label col-form-label-sm">BOL Number:</label>
                                {/* if props.webquotecontrol contains a comma seperate and display individually */}

                                {
                                    props.webquotecontrol && typeof props.webquotecontrol === 'string' && props.webquotecontrol.includes(',') ?
                                        <>
                                            <div className='d-flex flex-column'>
                                                {props.webquotecontrol.split(',').map((shipment, index) => {
                                                    return (
                                                        <span key={index}>{shipment}</span>
                                                    )
                                                }
                                                )}
                                            </div>
                                        </>
                                        :
                                        <div id="shipmentId"> {props.webquotecontrol}</div>
                                }
                            </>
                            : null}
                        {/* <input type="text" id="webquotecontrol" name="webquotecontrol" className="form-control form-control-sm e-field e-input" aria-label="BOL" /> */}
                        <br />

                        <ApptEditorTemplateRruleCustomFields props={props} localusercode={localusercode} currentlySelectedDate={currentlySelectedDate} supplierWindowsMode={supplierWindowsMode} />
                    </div>
                    <div className="col-sm-3">
                        <label htmlFor="dockEventNumber" className="form-label col-form-label-sm">Dock Appt #:</label>
                        <div id="dockEventNumber"> {props.id}</div>
                    </div>
                </div>

                {!props.RecurrenceRule && !supplierWindowsMode ?
                    (
                        <div id="ucInputFields" className="row mb-3">
                            <span></span>
                            <div className="col-sm-6">
                                <label htmlFor="usercontrol" className="form-label col-form-label-sm">{props.userControlLabel ? (props.userControlLabel) : (<span>Order</span>)}:</label>
                                <input className="form-control form-control-sm e-field e-input" id='usercontrol' name="usercontrol" data-name="usercontrol" type="text" onChange={(event) => validateUsercontrol(event)} />
                                <div className='d-flex justify-content-center'>
                                    {multiShipmentApptMode ? <span type="button" className="btn btn-sm btn-outline-primary mt-2" onClick={() => validateMultipleOrders(multipleOrders, selectedDock)}> Validate Order(s)</span> : null}
                                </div>

                            </div>
                            <div className="col-sm-6">
                                <ValidatedField validated={validated} value={ucValue} message={validationMessage} />
                            </div>
                        </div>
                    )
                    : null}

                {/* <div className="row mb-3">
                    <div className="col-sm-6">
                        <input className="form-control form-control-sm e-field e-input" id='usercode' name="usercode" data-name="usercode" type="text" /> {localusercode}
                    </div>
                </div> */}
                {props.webquotecontrol || props.RecurrenceRule || props.id ? null :
                    <>
                        <div onClick={() => noShipmentsAssignedToRecurrenceEvents()} style={recurrenceStyleForHiding}>
                            <RecurrenceEditorComponent id='RecurrenceEditor' className="e-field e-input" data-name="RecurrenceRule" ref={t => recObject = t} />
                        </div>
                        <div>
                            {/* <SupplierDropdown suppliers="" currentSupplier="" /> */}
                        </div>
                    </>
                }

                {props.RecurrenceRule ? <div className="alert alert-info" role="alert">{capitalizeFirstLetter(ruleText)} </div> : null}

                {(localusercode === 'momcorp' || localusercode === 'kwiktrip') ? <SupplierWindows props={props} localusercode={localusercode} supplierWindowsMode={supplierWindowsMode} setSupplierWindowsMode={setSupplierWindowsMode} /> : null}

                {(props?.webquotecontrol) && (localusercode === 'momcorp' || localusercode === 'kwiktrip') ? <InOutTimes shipment={shipmentDetails} appointmentProps={props} /> : null}
            </div >

        )
    }
}

export default ApptEditorTemplate