
export const getUUIDValidation = function (
    uuid,
    location
) {
    if (location === undefined) {
        console.error('Oh noes getUUIDValidation location is undefined!')
    }
    return new Promise(function (resolve, reject) {

        let controller = new AbortController();

        let url =
            "https://api.shippersedge.com/schedule/validateuuid/" + location + "/" + uuid

        let myHeaders = new Headers();
        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let request = new Request(url, requestOptions);

        // if (timeOutValue) {
        //     setTimeout(() => controller.abort(), timeOutValue);
        // }

        fetch(request)
            .then((response) => {
                // console.log(
                //   "debug getUUIDValidation.js response",
                //   response
                // );
                if (!response.ok) {
                    console.error(
                        "getUUIDValidation.js error",
                        response.statusText,
                        response
                    );
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                console.error("getUUIDValidation API Request failed", err);
                resolve(err);
            });
    });
};

export default getUUIDValidation