// transFormEvents ads a property to the dock events to fit syncfusion's formatting for date and times

import moment from 'moment';

export const transFormEvents = async (type, eventsArray) => {

    if (type === 'incomming') {
        if (Array.isArray(eventsArray)) {
            let newEventsArray = []
            for (let dockEvent of eventsArray) {
                dockEvent.StartTime = removeTimeZone(dockEvent.timeIn)
                dockEvent.EndTime = removeTimeZone(dockEvent.timeOut)
                dockEvent.Subject = dockEvent.description
                newEventsArray.push(dockEvent)
            }
            return newEventsArray
        }
        else {
            console.warn("transFormEvents(incomming) saw a non array passed to it, not sure why")
        }
    } else if (type === 'outgoing') {
        if (Array.isArray(eventsArray)) {
            let newEventsArray = []
            let adjustments = await outgoingAdjustments(eventsArray, newEventsArray)
            return adjustments
        } else {
            console.warn("transFormEvents(outgoing) saw a non array passed to it, not sure why")
        }

    }

}


async function outgoingAdjustments(eventsArray, newEventsArray) {
    for (let dockEvent of eventsArray) {
        if (dockEvent.webquotecontrol) {
            dockEvent.shipmentId = dockEvent.webquotecontrol
        }
        if (dockEvent.Subject && dockEvent.Subject !== "") {
            dockEvent.description = dockEvent.Subject
        }
        dockEvent.timeIn = moment(dockEvent.StartTime).format('YYYY-MM-DD[T]HH:mm:ss')
        dockEvent.timeOut = moment(dockEvent.EndTime).format('YYYY-MM-DD[T]HH:mm:ss')
        newEventsArray.push(dockEvent)
    }
    return newEventsArray
}

// When the dates are comming in from the DB they have 0000Z at the end.  That indicates it is a 0 UTC based time with no offset, new Date then takes that offset and applies it based on the local system time transforming the date based on the local timezone.  But in reality the timestamp is not in any time zone (it is in centeral time but when the user enters the date is assumed to be just whatever the time is at the dock).   Here we are trimming the timezone off of the incomming timeIn and timeOut and then the ofset isn't considered when using new Date
function removeTimeZone(dateTimeString) {
    return new Date(dateTimeString.slice(0, -5))
}

export default transFormEvents