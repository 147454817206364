import React from 'react'

const FourOhFour = () => {
    return (
        <div className="container">
            <header className="App-header">
                <h1>404</h1>
                <h4>Page not found.</h4>
                <h4>Please check the link that brought you here.</h4>
            </header>
        </div>
    )
}

export default FourOhFour
